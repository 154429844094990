/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const device_link_events = /* GraphQL */ `query Device_link_events($id: String!) {
  device_link_events(id: $id) {
    created
    created_by
    device_link_id
    id
    event_name
    event_id
    updated
    updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.Device_link_eventsQueryVariables,
  APITypes.Device_link_eventsQuery
>;
export const getActionV1 = /* GraphQL */ `query GetActionV1($id: String!) {
  getActionV1(id: $id) {
    id
    name
    description
    additional_info
    created
    created_by
    updated
    updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetActionV1QueryVariables,
  APITypes.GetActionV1Query
>;
export const getActionParameterV1 = /* GraphQL */ `query GetActionParameterV1($id: String!) {
  getActionParameterV1(id: $id) {
    id
    action_id
    parameter_id
    parameter_name
    additional_info
    created
    created_by
    updated
    updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetActionParameterV1QueryVariables,
  APITypes.GetActionParameterV1Query
>;
export const getCachedSiteCameras = /* GraphQL */ `query GetCachedSiteCameras($siteCode: String!) {
  getCachedSiteCameras(siteCode: $siteCode) {
    siteCode
    created
    cameras {
      name
      description
      systemIdentifier
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCachedSiteCamerasQueryVariables,
  APITypes.GetCachedSiteCamerasQuery
>;
export const getCachedSiteCamerasV1 = /* GraphQL */ `query GetCachedSiteCamerasV1($siteCode: String!) {
  getCachedSiteCamerasV1(siteCode: $siteCode) {
    siteCode
    created
    cameras {
      name
      description
      systemIdentifier
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCachedSiteCamerasV1QueryVariables,
  APITypes.GetCachedSiteCamerasV1Query
>;
export const getConditionOperandV1 = /* GraphQL */ `query GetConditionOperandV1($id: String!) {
  getConditionOperandV1(id: $id) {
    id
    name
    description
    additional_info
    created
    created_by
    updated
    updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetConditionOperandV1QueryVariables,
  APITypes.GetConditionOperandV1Query
>;
export const getDeviceActionV1 = /* GraphQL */ `query GetDeviceActionV1($id: String!) {
  getDeviceActionV1(id: $id) {
    id
    site_code
    input_device_id
    output_device_id
    additional_info
    created
    created_by
    updated
    updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDeviceActionV1QueryVariables,
  APITypes.GetDeviceActionV1Query
>;
export const getDeviceActionConditionGroupV1 = /* GraphQL */ `query GetDeviceActionConditionGroupV1($id: String!) {
  getDeviceActionConditionGroupV1(id: $id) {
    id
    device_action_id
    device_action_conditions {
      id
      group_id
      operator
      operand_id
      operand_name
      value
      created
      created_by
      updated
      updated_by
      __typename
    }
    operator
    created
    created_by
    updated
    updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDeviceActionConditionGroupV1QueryVariables,
  APITypes.GetDeviceActionConditionGroupV1Query
>;
export const getDeviceActionConditionV1 = /* GraphQL */ `query GetDeviceActionConditionV1($id: String!) {
  getDeviceActionConditionV1(id: $id) {
    id
    group_id
    operator
    operand_id
    operand_name
    value
    created
    created_by
    updated
    updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDeviceActionConditionV1QueryVariables,
  APITypes.GetDeviceActionConditionV1Query
>;
export const getDeviceActionParameterV1 = /* GraphQL */ `query GetDeviceActionParameterV1($id: String!) {
  getDeviceActionParameterV1(id: $id) {
    id
    device_action_id
    action_id
    action_name
    parameter_id
    parameter_name
    value
    created
    created_by
    updated
    updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDeviceActionParameterV1QueryVariables,
  APITypes.GetDeviceActionParameterV1Query
>;
export const getDeviceEventV1 = /* GraphQL */ `query GetDeviceEventV1($id: String!) {
  getDeviceEventV1(id: $id) {
    id
    name
    description
    additional_info
    created
    created_by
    updated
    updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDeviceEventV1QueryVariables,
  APITypes.GetDeviceEventV1Query
>;
export const getActionDeviceTypeEventV1 = /* GraphQL */ `query GetActionDeviceTypeEventV1($id: String!) {
  getActionDeviceTypeEventV1(id: $id) {
    id
    device_event_id
    action_device_type_id
    additional_info
    created
    created_by
    updated
    updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetActionDeviceTypeEventV1QueryVariables,
  APITypes.GetActionDeviceTypeEventV1Query
>;
export const getDeviceLink = /* GraphQL */ `query GetDeviceLink($id: ID!) {
  getDeviceLink(id: $id) {
    acd_device_name
    acd_device_type
    acd_parent_device_id
    acd_child_device_id
    acd_subchild_device_id
    camera_name
    camera_system_id
    created
    created_by
    enabled
    event_links
    id
    system_id
    system_name
    updated
    updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDeviceLinkQueryVariables,
  APITypes.GetDeviceLinkQuery
>;
export const getDeviceLinkV1 = /* GraphQL */ `query GetDeviceLinkV1($id: ID!) {
  getDeviceLinkV1(id: $id) {
    acd_device_name
    acd_device_type
    acd_parent_device_id
    acd_child_device_id
    acd_subchild_device_id
    camera_name
    camera_system_id
    created
    created_by
    enabled
    event_links
    id
    system_id
    system_name
    updated
    updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDeviceLinkV1QueryVariables,
  APITypes.GetDeviceLinkV1Query
>;
export const getDeviceLinkEvent = /* GraphQL */ `query GetDeviceLinkEvent($id: ID!) {
  getDeviceLinkEvent(id: $id) {
    created
    created_by
    device_link_id
    id
    event_name
    event_id
    updated
    updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDeviceLinkEventQueryVariables,
  APITypes.GetDeviceLinkEventQuery
>;
export const getDeviceLinkEventV1 = /* GraphQL */ `query GetDeviceLinkEventV1($id: ID!) {
  getDeviceLinkEventV1(id: $id) {
    created
    created_by
    device_link_id
    id
    event_name
    event_id
    updated
    updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDeviceLinkEventV1QueryVariables,
  APITypes.GetDeviceLinkEventV1Query
>;
export const getDeviceTypeV1 = /* GraphQL */ `query GetDeviceTypeV1($id: ID!) {
  getDeviceTypeV1(id: $id) {
    id
    name
    description
    additional_info
    created
    created_by
    updated
    updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDeviceTypeV1QueryVariables,
  APITypes.GetDeviceTypeV1Query
>;
export const getActionDeviceTypeV1 = /* GraphQL */ `query GetActionDeviceTypeV1($id: ID!) {
  getActionDeviceTypeV1(id: $id) {
    id
    action_id
    device_type_id
    action_device_type
    additional_info
    created
    created_by
    updated
    updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetActionDeviceTypeV1QueryVariables,
  APITypes.GetActionDeviceTypeV1Query
>;
export const getEvent = /* GraphQL */ `query GetEvent($id: ID!) {
  getEvent(id: $id) {
    created
    created_by
    device_type
    id
    name
    updated
    updated_by
    __typename
  }
}
` as GeneratedQuery<APITypes.GetEventQueryVariables, APITypes.GetEventQuery>;
export const getEventV1 = /* GraphQL */ `query GetEventV1($id: ID!) {
  getEventV1(id: $id) {
    created
    created_by
    device_type
    id
    name
    updated
    updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetEventV1QueryVariables,
  APITypes.GetEventV1Query
>;
export const getEventLinksForSystemId = /* GraphQL */ `query GetEventLinksForSystemId($system_id: ID!) {
  getEventLinksForSystemId(system_id: $system_id) {
    event_links {
      device_link_id
      devices {
        cameraName
        cameraSystemIdentifier
        accessControlDeviceName
        accessControlDeviceParentDeviceID
        accessControlDeviceChildDeviceID
        accessControlDeviceSubchildDeviceID
        accessControlDeviceType
        __typename
      }
      events
      system {
        auth_mode
        system_id
        secondary_system_id
        facman_sitename
        srvr_alrm_srvc_path
        srvr_alrm_srvc_port
        srvr_cmd_srvc_path
        srvr_cmd_srvc_port
        srvr_cnf_srvc_path
        srvr_cnf_srvc_port
        srvr_vpc_endpoint
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetEventLinksForSystemIdQueryVariables,
  APITypes.GetEventLinksForSystemIdQuery
>;
export const getEventLinksForSystemIdV1 = /* GraphQL */ `query GetEventLinksForSystemIdV1($system_id: ID!) {
  getEventLinksForSystemIdV1(system_id: $system_id) {
    event_links {
      device_link_id
      devices {
        cameraName
        cameraSystemIdentifier
        accessControlDeviceName
        accessControlDeviceParentDeviceID
        accessControlDeviceChildDeviceID
        accessControlDeviceSubchildDeviceID
        accessControlDeviceType
        __typename
      }
      events
      system {
        auth_mode
        system_id
        secondary_system_id
        facman_sitename
        srvr_alrm_srvc_path
        srvr_alrm_srvc_port
        srvr_cmd_srvc_path
        srvr_cmd_srvc_port
        srvr_cnf_srvc_path
        srvr_cnf_srvc_port
        srvr_vpc_endpoint
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetEventLinksForSystemIdV1QueryVariables,
  APITypes.GetEventLinksForSystemIdV1Query
>;
export const getParameterV1 = /* GraphQL */ `query GetParameterV1($id: ID!) {
  getParameterV1(id: $id) {
    id
    name
    description
    additional_info
    created
    created_by
    updated
    updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetParameterV1QueryVariables,
  APITypes.GetParameterV1Query
>;
export const getSystem = /* GraphQL */ `query GetSystem($id: ID!) {
  getSystem(id: $id) {
    auth_mode
    created
    created_by
    enabled
    id
    name
    secondary_system_id
    secondary_system_name
    segment_name
    segment_location
    segment_source
    srvr_alrm_srvc_path
    srvr_alrm_srvc_port
    srvr_cmd_srvc_path
    srvr_cmd_srvc_port
    srvr_cnf_srvc_path
    srvr_cnf_srvc_port
    srvr_vpc_endpoint
    updated
    updated_by
    __typename
  }
}
` as GeneratedQuery<APITypes.GetSystemQueryVariables, APITypes.GetSystemQuery>;
export const getSystemV1 = /* GraphQL */ `query GetSystemV1($id: ID!) {
  getSystemV1(id: $id) {
    auth_mode
    created
    created_by
    enabled
    id
    name
    secondary_system_id
    secondary_system_name
    segment_name
    segment_location
    segment_source
    srvr_alrm_srvc_path
    srvr_alrm_srvc_port
    srvr_cmd_srvc_path
    srvr_cmd_srvc_port
    srvr_cnf_srvc_path
    srvr_cnf_srvc_port
    srvr_vpc_endpoint
    updated
    updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSystemV1QueryVariables,
  APITypes.GetSystemV1Query
>;
export const getSystemForSegmentName = /* GraphQL */ `query GetSystemForSegmentName($segmentName: String!) {
  getSystemForSegmentName(segmentName: $segmentName) {
    auth_mode
    created
    created_by
    enabled
    id
    name
    secondary_system_id
    secondary_system_name
    segment_name
    segment_location
    segment_source
    srvr_alrm_srvc_path
    srvr_alrm_srvc_port
    srvr_cmd_srvc_path
    srvr_cmd_srvc_port
    srvr_cnf_srvc_path
    srvr_cnf_srvc_port
    srvr_vpc_endpoint
    updated
    updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSystemForSegmentNameQueryVariables,
  APITypes.GetSystemForSegmentNameQuery
>;
export const getSystemForSegmentNameV1 = /* GraphQL */ `query GetSystemForSegmentNameV1($segmentName: String!) {
  getSystemForSegmentNameV1(segmentName: $segmentName) {
    auth_mode
    created
    created_by
    enabled
    id
    name
    secondary_system_id
    secondary_system_name
    segment_name
    segment_location
    segment_source
    srvr_alrm_srvc_path
    srvr_alrm_srvc_port
    srvr_cmd_srvc_path
    srvr_cmd_srvc_port
    srvr_cnf_srvc_path
    srvr_cnf_srvc_port
    srvr_vpc_endpoint
    updated
    updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSystemForSegmentNameV1QueryVariables,
  APITypes.GetSystemForSegmentNameV1Query
>;
export const listAccessControlDevices = /* GraphQL */ `query ListAccessControlDevices($path: String!) {
  listAccessControlDevices(path: $path) {
    Parent_DeviceID
    Child_DeviceID
    Subchild_DeviceID
    Device_Type
    DeviceName
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAccessControlDevicesQueryVariables,
  APITypes.ListAccessControlDevicesQuery
>;
export const listAccessControlDevicesV1 = /* GraphQL */ `query ListAccessControlDevicesV1($path: String!) {
  listAccessControlDevicesV1(path: $path) {
    Parent_DeviceID
    Child_DeviceID
    Subchild_DeviceID
    Device_Type
    DeviceName
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAccessControlDevicesV1QueryVariables,
  APITypes.ListAccessControlDevicesV1Query
>;
export const listActionDeviceTypesV1 = /* GraphQL */ `query ListActionDeviceTypesV1 {
  listActionDeviceTypesV1 {
    id
    action_id
    device_type_id
    action_device_type
    additional_info
    created
    created_by
    updated
    updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListActionDeviceTypesV1QueryVariables,
  APITypes.ListActionDeviceTypesV1Query
>;
export const listActionDeviceTypeEventsForActionDeviceTypeV1 = /* GraphQL */ `query ListActionDeviceTypeEventsForActionDeviceTypeV1(
  $action_device_type_id: String!
) {
  listActionDeviceTypeEventsForActionDeviceTypeV1(
    action_device_type_id: $action_device_type_id
  ) {
    id
    action_device_type_id
    action_device_type
    action_id
    action_name
    device_event_id
    device_event_name
    device_type_id
    device_type_name
    additional_info
    created
    created_by
    updated
    updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListActionDeviceTypeEventsForActionDeviceTypeV1QueryVariables,
  APITypes.ListActionDeviceTypeEventsForActionDeviceTypeV1Query
>;
export const listActionDeviceTypeEventsV1 = /* GraphQL */ `query ListActionDeviceTypeEventsV1 {
  listActionDeviceTypeEventsV1 {
    id
    device_event_id
    action_device_type_id
    additional_info
    created
    created_by
    updated
    updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListActionDeviceTypeEventsV1QueryVariables,
  APITypes.ListActionDeviceTypeEventsV1Query
>;
export const listActionsV1 = /* GraphQL */ `query ListActionsV1 {
  listActionsV1 {
    id
    name
    description
    additional_info
    created
    created_by
    updated
    updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListActionsV1QueryVariables,
  APITypes.ListActionsV1Query
>;
export const listActionParametersV1 = /* GraphQL */ `query ListActionParametersV1($action_id: String!) {
  listActionParametersV1(action_id: $action_id) {
    id
    action_id
    parameter_id
    parameter_name
    additional_info
    created
    created_by
    updated
    updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListActionParametersV1QueryVariables,
  APITypes.ListActionParametersV1Query
>;
export const listDevices = /* GraphQL */ `query ListDevices($methodName: ListDevicesMethodName, $parameters: AWSJSON!) {
  listDevices(methodName: $methodName, parameters: $parameters) {
    message
    statusCode
    devices {
      id
      device_type_id
      device_type
      device_type_name
      device_family
      name
      source_system_device_id
      device_id_key
      parent_id
      parent_name
      grand_parent_id
      grand_parent_name
      site_code
      site_type
      site_group
      site_region
      latitude
      longitude
      country
      zone
      timezone_offset_minutes
      timezone_name
      system_id
      source_system
      source_stack
      source_location
      source_version
      source_instance
      last_updated_at
      device_int_id
      parent_device_int_id
      grand_parent_device_int_id
      deleted_at_timestamp
      communication_address
      communication_port
      communication_server_name
      device_ip_primary
      device_ip_secondary
      source_device_details
      reporting_details
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDevicesQueryVariables,
  APITypes.ListDevicesQuery
>;
export const listDevicesV1 = /* GraphQL */ `query ListDevicesV1($methodName: ListDevicesMethodName, $parameters: AWSJSON!) {
  listDevicesV1(methodName: $methodName, parameters: $parameters) {
    message
    statusCode
    devices {
      id
      device_type_id
      device_type
      device_type_name
      device_family
      name
      source_system_device_id
      device_id_key
      parent_id
      parent_name
      grand_parent_id
      grand_parent_name
      site_code
      site_type
      site_group
      site_region
      latitude
      longitude
      country
      zone
      timezone_offset_minutes
      timezone_name
      system_id
      source_system
      source_stack
      source_location
      source_version
      source_instance
      last_updated_at
      device_int_id
      parent_device_int_id
      grand_parent_device_int_id
      deleted_at_timestamp
      communication_address
      communication_port
      communication_server_name
      device_ip_primary
      device_ip_secondary
      source_device_details
      reporting_details
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDevicesV1QueryVariables,
  APITypes.ListDevicesV1Query
>;
export const listParametersForActionV1 = /* GraphQL */ `query ListParametersForActionV1($action_id: String!) {
  listParametersForActionV1(action_id: $action_id) {
    id
    action_id
    action_name
    parameter_id
    parameter_name
    additional_info
    created
    created_by
    updated
    updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListParametersForActionV1QueryVariables,
  APITypes.ListParametersForActionV1Query
>;
export const listAudit = /* GraphQL */ `query ListAudit(
  $startDate: String!
  $endDate: String!
  $limit: Int!
  $offset: Int!
) {
  listAudit(
    startDate: $startDate
    endDate: $endDate
    limit: $limit
    offset: $offset
  ) {
    event_id
    table_name
    action_tstamp_tx
    action
    row_data
    row_data_json
    changed_fields
    changed_fields_json
    __typename
  }
}
` as GeneratedQuery<APITypes.ListAuditQueryVariables, APITypes.ListAuditQuery>;
export const listAuditV1 = /* GraphQL */ `query ListAuditV1(
  $startDate: String!
  $endDate: String!
  $limit: Int!
  $offset: Int!
) {
  listAuditV1(
    startDate: $startDate
    endDate: $endDate
    limit: $limit
    offset: $offset
  ) {
    event_id
    table_name
    action_tstamp_tx
    action
    row_data
    row_data_json
    changed_fields
    changed_fields_json
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAuditV1QueryVariables,
  APITypes.ListAuditV1Query
>;
export const listConditionOperandsV1 = /* GraphQL */ `query ListConditionOperandsV1 {
  listConditionOperandsV1 {
    id
    name
    description
    additional_info
    created
    created_by
    updated
    updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListConditionOperandsV1QueryVariables,
  APITypes.ListConditionOperandsV1Query
>;
export const listDeviceActionOutputActionsV1 = /* GraphQL */ `query ListDeviceActionOutputActionsV1($device_action_id: String!) {
  listDeviceActionOutputActionsV1(device_action_id: $device_action_id) {
    id
    device_action_id
    action_id
    action_name
    created
    created_by
    updated
    updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDeviceActionOutputActionsV1QueryVariables,
  APITypes.ListDeviceActionOutputActionsV1Query
>;
export const listDeviceActionConditionGroupsForDeviceActionV1 = /* GraphQL */ `query ListDeviceActionConditionGroupsForDeviceActionV1(
  $device_action_id: String!
) {
  listDeviceActionConditionGroupsForDeviceActionV1(
    device_action_id: $device_action_id
  ) {
    id
    device_action_id
    device_action_conditions {
      id
      group_id
      operator
      operand_id
      operand_name
      value
      created
      created_by
      updated
      updated_by
      __typename
    }
    operator
    created
    created_by
    updated
    updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDeviceActionConditionGroupsForDeviceActionV1QueryVariables,
  APITypes.ListDeviceActionConditionGroupsForDeviceActionV1Query
>;
export const listDeviceActionConditionsForGroupV1 = /* GraphQL */ `query ListDeviceActionConditionsForGroupV1($group_id: String!) {
  listDeviceActionConditionsForGroupV1(group_id: $group_id) {
    id
    group_id
    operator
    operand_id
    operand_name
    value
    created
    created_by
    updated
    updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDeviceActionConditionsForGroupV1QueryVariables,
  APITypes.ListDeviceActionConditionsForGroupV1Query
>;
export const listDeviceActionParametersForDeviceActionV1 = /* GraphQL */ `query ListDeviceActionParametersForDeviceActionV1($device_action_id: String!) {
  listDeviceActionParametersForDeviceActionV1(
    device_action_id: $device_action_id
  ) {
    id
    device_action_id
    action_id
    action_name
    parameter_id
    parameter_name
    value
    created
    created_by
    updated
    updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDeviceActionParametersForDeviceActionV1QueryVariables,
  APITypes.ListDeviceActionParametersForDeviceActionV1Query
>;
export const listDeviceActionsForSiteV1 = /* GraphQL */ `query ListDeviceActionsForSiteV1($site_code: String!) {
  listDeviceActionsForSiteV1(site_code: $site_code) {
    id
    site_code
    device_action_condition_groups {
      id
      device_action_id
      device_action_conditions {
        id
        group_id
        operator
        operand_id
        operand_name
        value
        created
        created_by
        updated
        updated_by
        __typename
      }
      operator
      created
      created_by
      updated
      updated_by
      __typename
    }
    device_action_input_events {
      id
      device_action_id
      device_event_id
      device_event_name
      created
      created_by
      updated
      updated_by
      __typename
    }
    device_action_output_actions {
      id
      device_action_id
      action_id
      action_name
      created
      created_by
      updated
      updated_by
      __typename
    }
    device_action_parameters {
      id
      device_action_id
      action_id
      action_name
      parameter_id
      parameter_name
      value
      created
      created_by
      updated
      updated_by
      __typename
    }
    input_device_id
    output_device_id
    additional_info
    created
    created_by
    updated
    updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDeviceActionsForSiteV1QueryVariables,
  APITypes.ListDeviceActionsForSiteV1Query
>;
export const listDeviceEventsV1 = /* GraphQL */ `query ListDeviceEventsV1 {
  listDeviceEventsV1 {
    id
    name
    description
    additional_info
    created
    created_by
    updated
    updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDeviceEventsV1QueryVariables,
  APITypes.ListDeviceEventsV1Query
>;
export const listDeviceTypesV1 = /* GraphQL */ `query ListDeviceTypesV1 {
  listDeviceTypesV1 {
    id
    name
    description
    additional_info
    created
    created_by
    updated
    updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDeviceTypesV1QueryVariables,
  APITypes.ListDeviceTypesV1Query
>;
export const listDeviceTypesForActionV1 = /* GraphQL */ `query ListDeviceTypesForActionV1($action_id: String!) {
  listDeviceTypesForActionV1(action_id: $action_id) {
    id
    action_id
    action_name
    action_device_type
    device_type_id
    device_type_name
    device_type_description
    additional_info
    created
    created_by
    updated
    updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDeviceTypesForActionV1QueryVariables,
  APITypes.ListDeviceTypesForActionV1Query
>;
export const listSiteCameras = /* GraphQL */ `query ListSiteCameras(
  $siteCode: String!
  $authMode: String!
  $cmdSrvcEndpoint: String!
  $cmdSrvcPath: String!
  $cmdSrvcPort: String!
) {
  listSiteCameras(
    siteCode: $siteCode
    authMode: $authMode
    cmdSrvcEndpoint: $cmdSrvcEndpoint
    cmdSrvcPath: $cmdSrvcPath
    cmdSrvcPort: $cmdSrvcPort
  ) {
    name
    description
    systemIdentifier
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSiteCamerasQueryVariables,
  APITypes.ListSiteCamerasQuery
>;
export const listSiteCamerasV1 = /* GraphQL */ `query ListSiteCamerasV1(
  $siteCode: String!
  $authMode: String!
  $cmdSrvcEndpoint: String!
  $cmdSrvcPath: String!
  $cmdSrvcPort: String!
) {
  listSiteCamerasV1(
    siteCode: $siteCode
    authMode: $authMode
    cmdSrvcEndpoint: $cmdSrvcEndpoint
    cmdSrvcPath: $cmdSrvcPath
    cmdSrvcPort: $cmdSrvcPort
  ) {
    name
    description
    systemIdentifier
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSiteCamerasV1QueryVariables,
  APITypes.ListSiteCamerasV1Query
>;
export const listDeviceLinkEventsForDeviceLink = /* GraphQL */ `query ListDeviceLinkEventsForDeviceLink($device_link_id: ID!) {
  listDeviceLinkEventsForDeviceLink(device_link_id: $device_link_id) {
    created
    created_by
    device_link_id
    id
    event_name
    event_id
    updated
    updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDeviceLinkEventsForDeviceLinkQueryVariables,
  APITypes.ListDeviceLinkEventsForDeviceLinkQuery
>;
export const listDeviceLinkEventsForDeviceLinkV1 = /* GraphQL */ `query ListDeviceLinkEventsForDeviceLinkV1($device_link_id: ID!) {
  listDeviceLinkEventsForDeviceLinkV1(device_link_id: $device_link_id) {
    created
    created_by
    device_link_id
    id
    event_name
    event_id
    updated
    updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDeviceLinkEventsForDeviceLinkV1QueryVariables,
  APITypes.ListDeviceLinkEventsForDeviceLinkV1Query
>;
export const listDeviceLinkTypes = /* GraphQL */ `query ListDeviceLinkTypes {
  listDeviceLinkTypes {
    id
    name
    description
    additional_info
    created
    created_by
    updated
    updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDeviceLinkTypesQueryVariables,
  APITypes.ListDeviceLinkTypesQuery
>;
export const listDeviceLinkTypeActions = /* GraphQL */ `query ListDeviceLinkTypeActions {
  listDeviceLinkTypeActions {
    id
    action_id
    device_type_id
    action_device_type
    additional_info
    created
    created_by
    updated
    updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDeviceLinkTypeActionsQueryVariables,
  APITypes.ListDeviceLinkTypeActionsQuery
>;
export const listDeviceLinksForSite = /* GraphQL */ `query ListDeviceLinksForSite($segment_name: String!) {
  listDeviceLinksForSite(segment_name: $segment_name) {
    acd_device_name
    acd_device_type
    acd_parent_device_id
    acd_child_device_id
    acd_subchild_device_id
    camera_name
    camera_system_id
    created
    created_by
    enabled
    event_links
    id
    system_id
    system_name
    updated
    updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDeviceLinksForSiteQueryVariables,
  APITypes.ListDeviceLinksForSiteQuery
>;
export const listDeviceLinksForSiteV1 = /* GraphQL */ `query ListDeviceLinksForSiteV1($segment_name: String!) {
  listDeviceLinksForSiteV1(segment_name: $segment_name) {
    acd_device_name
    acd_device_type
    acd_parent_device_id
    acd_child_device_id
    acd_subchild_device_id
    camera_name
    camera_system_id
    created
    created_by
    enabled
    event_links
    id
    system_id
    system_name
    updated
    updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDeviceLinksForSiteV1QueryVariables,
  APITypes.ListDeviceLinksForSiteV1Query
>;
export const listDeviceLinksForSiteV2 = /* GraphQL */ `query ListDeviceLinksForSiteV2($segment_name: String!) {
  listDeviceLinksForSiteV2(segment_name: $segment_name) {
    acd_device_name
    acd_device_type
    acd_parent_device_id
    acd_child_device_id
    acd_subchild_device_id
    camera_name
    camera_system_id
    created
    created_by
    enabled
    device_link_events {
      created
      created_by
      device_link_id
      id
      event_name
      event_id
      updated
      updated_by
      __typename
    }
    id
    system_id
    system_name
    updated
    updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDeviceLinksForSiteV2QueryVariables,
  APITypes.ListDeviceLinksForSiteV2Query
>;
export const listEvents = /* GraphQL */ `query ListEvents {
  listEvents {
    created
    created_by
    device_type
    id
    name
    updated
    updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListEventsQueryVariables,
  APITypes.ListEventsQuery
>;
export const listEventsV1 = /* GraphQL */ `query ListEventsV1 {
  listEventsV1 {
    created
    created_by
    device_type
    id
    name
    updated
    updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListEventsV1QueryVariables,
  APITypes.ListEventsV1Query
>;
export const listParametersV1 = /* GraphQL */ `query ListParametersV1 {
  listParametersV1 {
    id
    name
    description
    additional_info
    created
    created_by
    updated
    updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListParametersV1QueryVariables,
  APITypes.ListParametersV1Query
>;
export const listSIGInfraUserSites = /* GraphQL */ `query ListSIGInfraUserSites($username: String!) {
  listSIGInfraUserSites(username: $username) {
    allSites
    sites
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSIGInfraUserSitesQueryVariables,
  APITypes.ListSIGInfraUserSitesQuery
>;
export const listSIGInfraUserSitesV1 = /* GraphQL */ `query ListSIGInfraUserSitesV1($username: String!) {
  listSIGInfraUserSitesV1(username: $username) {
    allSites
    sites
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSIGInfraUserSitesV1QueryVariables,
  APITypes.ListSIGInfraUserSitesV1Query
>;
export const listSystems = /* GraphQL */ `query ListSystems {
  listSystems {
    auth_mode
    created
    created_by
    enabled
    id
    name
    secondary_system_id
    secondary_system_name
    segment_name
    segment_location
    segment_source
    srvr_alrm_srvc_path
    srvr_alrm_srvc_port
    srvr_cmd_srvc_path
    srvr_cmd_srvc_port
    srvr_cnf_srvc_path
    srvr_cnf_srvc_port
    srvr_vpc_endpoint
    updated
    updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSystemsQueryVariables,
  APITypes.ListSystemsQuery
>;
export const listSystemsV1 = /* GraphQL */ `query ListSystemsV1 {
  listSystemsV1 {
    auth_mode
    created
    created_by
    enabled
    id
    name
    secondary_system_id
    secondary_system_name
    segment_name
    segment_location
    segment_source
    srvr_alrm_srvc_path
    srvr_alrm_srvc_port
    srvr_cmd_srvc_path
    srvr_cmd_srvc_port
    srvr_cnf_srvc_path
    srvr_cnf_srvc_port
    srvr_vpc_endpoint
    updated
    updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSystemsV1QueryVariables,
  APITypes.ListSystemsV1Query
>;
export const listSystemsForSite = /* GraphQL */ `query ListSystemsForSite($segment_name: String!) {
  listSystemsForSite(segment_name: $segment_name) {
    auth_mode
    created
    created_by
    enabled
    id
    name
    secondary_system_id
    secondary_system_name
    segment_name
    segment_location
    segment_source
    srvr_alrm_srvc_path
    srvr_alrm_srvc_port
    srvr_cmd_srvc_path
    srvr_cmd_srvc_port
    srvr_cnf_srvc_path
    srvr_cnf_srvc_port
    srvr_vpc_endpoint
    updated
    updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSystemsForSiteQueryVariables,
  APITypes.ListSystemsForSiteQuery
>;
export const listSystemsForSiteV1 = /* GraphQL */ `query ListSystemsForSiteV1($segment_name: String!) {
  listSystemsForSiteV1(segment_name: $segment_name) {
    auth_mode
    created
    created_by
    enabled
    id
    name
    secondary_system_id
    secondary_system_name
    segment_name
    segment_location
    segment_source
    srvr_alrm_srvc_path
    srvr_alrm_srvc_port
    srvr_cmd_srvc_path
    srvr_cmd_srvc_port
    srvr_cnf_srvc_path
    srvr_cnf_srvc_port
    srvr_vpc_endpoint
    updated
    updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSystemsForSiteV1QueryVariables,
  APITypes.ListSystemsForSiteV1Query
>;
export const listUserPermission = /* GraphQL */ `query ListUserPermission($path: String!) {
  listUserPermission(path: $path) {
    HASPERMISSION
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserPermissionQueryVariables,
  APITypes.ListUserPermissionQuery
>;
export const listUserPermissionV1 = /* GraphQL */ `query ListUserPermissionV1($path: String!) {
  listUserPermissionV1(path: $path) {
    HASPERMISSION
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserPermissionV1QueryVariables,
  APITypes.ListUserPermissionV1Query
>;
export const listUserSites = /* GraphQL */ `query ListUserSites($path: String!) {
  listUserSites(path: $path) {
    SegmentName
    SegmentLocation
    SegmentSource
    SiteCode
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserSitesQueryVariables,
  APITypes.ListUserSitesQuery
>;
export const listUserSitesV1 = /* GraphQL */ `query ListUserSitesV1($path: String!) {
  listUserSitesV1(path: $path) {
    SegmentName
    SegmentLocation
    SegmentSource
    SiteCode
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserSitesV1QueryVariables,
  APITypes.ListUserSitesV1Query
>;
