import {
  CreateDeviceActionConditionGroupInputV1,
  DeleteDeviceActionConditionGroupInputV1,
  DeviceActionConditionGroupV1,
  UpdateDeviceActionConditionGroupInputV1,
} from 'src/API';
import {
  Alert,
  Box,
  Button,
  Header,
  Modal,
  Select,
  SpaceBetween,
  Spinner,
  Table,
  TableProps,
} from '@amzn/awsui-components-react';
import {
  DefaultPageSize,
  TableEmptyState,
  TableNoMatchState,
} from './DeviceActionConditionGroupsTableConfig';
import {
  createDeviceActionConditionGroup,
  deleteDeviceActionConditionGroup,
  listDeviceActionConditionGroupsForDeviceActionId,
  updateDeviceActionConditionGroup,
} from './utils';
import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import DeviceActionConditionsTablePanel from './DeviceActionConditionsTablePanel';
import { ForceAwakensStateInterface } from 'src/stores/app';
import { State } from '@hookstate/core';
import { useBundle } from '@amzn/react-arb-tools';
import { useCollection } from '@amzn/awsui-collection-hooks';

interface IDeviceActionConditionGroupsTablePanel {
  forceAwakensState: State<ForceAwakensStateInterface>;
  deviceActionId: string;
}

export default function DeviceActionConditionGroupsTablePanel(props: IDeviceActionConditionGroupsTablePanel) {
  console.log(`DeviceActionConditionGroupsTablePanel() props.deviceActionId is ${JSON.stringify(props.deviceActionId)}`);

  const queryClient = useQueryClient();

  const [deviceActionConditionGroups, setDeviceActionConditionGroups] = useState<DeviceActionConditionGroupV1[]>([]);
  const [error, setError] = useState<string | undefined>();
  const [selectedDeviceActionConditionGroups, setSelectedDeviceActionConditionGroups] = useState<DeviceActionConditionGroupV1[]>([]);
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);

  const conditionGroupOperators = ['AND','OR'];

  const [bundle, isBundleLoading] = useBundle('components.DeviceActions.DeviceActionConditionGroupsTablePanel');

  const { items, actions: collectionActions, paginationProps } = useCollection(
    deviceActionConditionGroups,
    {
      filtering: {
        empty: <TableEmptyState title={isBundleLoading ? 'No ConditionGroups Found' : bundle.getMessage('no-condition-groups-found')} />,
        noMatch: <TableNoMatchState onClearFilter={() => collectionActions.setFiltering('')} />,
      },
      pagination: { pageSize: DefaultPageSize.pageSize },
      sorting: {},
      selection: { trackBy: 'id' }
    }
  );

  const deviceActionConditionGroupsQuery = useQuery(
    ['deviceActionConditionGroups'],
    async () => await listDeviceActionConditionGroupsForDeviceActionId(props.deviceActionId),
    {
      enabled: !!props.deviceActionId,
      onSettled: (data, error) => {
        setError(undefined);
        if (error) {
          setError(typeof error === 'object' ? JSON.stringify(error) : error as string);
          return;
        }
        setDeviceActionConditionGroups([]);
        if (data) setDeviceActionConditionGroups(data);
      },
      refetchOnWindowFocus: false,
    }
  );

  const saveDeviceActionConditionGroupMutation = useMutation<DeviceActionConditionGroupV1, Error, DeviceActionConditionGroupV1>(
    async (input: DeviceActionConditionGroupV1): Promise<DeviceActionConditionGroupV1> => {
      const currentDeviceActionConditionGroup = deviceActionConditionGroups.find(dap => dap.id !== '' && dap.id !== null && dap.id === input.id);
      if (currentDeviceActionConditionGroup) {
        const updateDeviceActionConditionGroupInput: UpdateDeviceActionConditionGroupInputV1 = {
          device_action_id: input.device_action_id,
          id: input.id,
          operator: input.operator,
          updated_by: props.forceAwakensState.username.value!,
        };
        return await updateDeviceActionConditionGroup(updateDeviceActionConditionGroupInput);
      }
      const createDeviceActionConditionGroupInput: CreateDeviceActionConditionGroupInputV1 = {
        created_by: props.forceAwakensState.username.value!,
        device_action_id: input.device_action_id,
        operator: input.operator,
      };
      return await createDeviceActionConditionGroup(createDeviceActionConditionGroupInput);
    },
    {
      onSettled: (data, error) => {
        setError(undefined);
        if (error) {
          setError(typeof error === 'object' ? JSON.stringify(error) : error as string);
          return;
        }
        const savedDeviceActionConditionGroupIndex = deviceActionConditionGroups.findIndex(a => a.id === data?.id);
        const newDeviceActionConditionGroups = [...deviceActionConditionGroups.filter(a => a.id !== data?.id && a.id !== '')];
        if (data) {
          const savedDeviceActionConditionGroup = {...data};
          if (savedDeviceActionConditionGroupIndex !== -1) newDeviceActionConditionGroups.splice(savedDeviceActionConditionGroupIndex, 0, savedDeviceActionConditionGroup);
          if (savedDeviceActionConditionGroupIndex === -1) newDeviceActionConditionGroups.push(savedDeviceActionConditionGroup);
        }
        if (deviceActionConditionGroups.findIndex(a => a.id === data?.id) === -1) collectionActions.setCurrentPage(paginationProps.pagesCount);
        setDeviceActionConditionGroups([...newDeviceActionConditionGroups]);
      },
    },
  );

  const deleteDeviceActionConditionGroupMutation = useMutation<DeviceActionConditionGroupV1 | undefined, Error, DeleteDeviceActionConditionGroupInputV1>(
    async (input: DeleteDeviceActionConditionGroupInputV1) => {
      const currentDeviceActionConditionGroup = deviceActionConditionGroups.find(dacg => dacg.id === input.id);
      if (currentDeviceActionConditionGroup && currentDeviceActionConditionGroup.id !== '') return(await deleteDeviceActionConditionGroup(input));
    },
    {
      onSettled: (data, error) => {
        setError(undefined);
        if (error) {
          setError(typeof error === 'object' ? JSON.stringify(error) : error as string);
          setShowConfirmDelete(false);
          return;
        }
        const newDeviceActionConditionGroups = [...deviceActionConditionGroups.filter(dacg => dacg.id !== data?.id)];
        setDeviceActionConditionGroups([...newDeviceActionConditionGroups]);
        setSelectedDeviceActionConditionGroups([]);
      },
    },
  );

  const submitEdit = async (
    currentItem: DeviceActionConditionGroupV1,
    column: TableProps.ColumnDefinition<DeviceActionConditionGroupV1>,
    value: any) =>
  {
    const newDeviceActionConditionGroups = [...deviceActionConditionGroups];
    const index = newDeviceActionConditionGroups.findIndex((v) => v.device_action_id === currentItem.device_action_id && v.operator === currentItem.operator);
    if (index !== -1) {
      const newDeviceActionConditionGroup: DeviceActionConditionGroupV1 = {...newDeviceActionConditionGroups[index]};
      switch (column.id) {
        case 'operator':
          newDeviceActionConditionGroup.operator = value.value;
          break;

        default:
          console.error(`submitEdit() column.id is ${column.id} which is not supported`);
      }
      newDeviceActionConditionGroups[index] = newDeviceActionConditionGroup;
      setDeviceActionConditionGroups(newDeviceActionConditionGroups);
      await saveDeviceActionConditionGroupMutation.mutateAsync(newDeviceActionConditionGroup);
    }
  };

  const selectConditionGroupRef = useRef<any>(null);

  const addDeviceActionConditionGroup = () => {
    const newDeviceActionConditionGroups = [...deviceActionConditionGroups];
    const newDeviceActionConditionGroup: DeviceActionConditionGroupV1 = {
      __typename: 'DeviceActionConditionGroupV1',
      id: '',
      created: '',
      created_by: props.forceAwakensState.username.value!,
      device_action_id: props.deviceActionId,
      operator: '',
      updated: '',
      updated_by: props.forceAwakensState.username.value!,
    };
    newDeviceActionConditionGroups.push(newDeviceActionConditionGroup);
    setDeviceActionConditionGroups(newDeviceActionConditionGroups);
    collectionActions.setCurrentPage(paginationProps.pagesCount);
    selectConditionGroupRef.current?.focus();
  };

  const deleteDeviceActionConditionGroups = async () => {
    if (selectedDeviceActionConditionGroups[0].id === '') {
      setDeviceActionConditionGroups(deviceActionConditionGroups.filter(dacg => dacg.id !== ''));
      setShowConfirmDelete(false);
      setError(undefined);
      return;
    }
    for (const selectedDeviceActionConditionGroup of selectedDeviceActionConditionGroups.filter(dap => dap.id !== '')) {
      const deleteDeviceActionConditionGroupInput: DeleteDeviceActionConditionGroupInputV1 = {
        id: selectedDeviceActionConditionGroup.id,
        updated_by: props.forceAwakensState.username.value!,
      };
      await deleteDeviceActionConditionGroupMutation.mutateAsync(deleteDeviceActionConditionGroupInput);
    }
    setShowConfirmDelete(false);
  };

  const refresh = () => {
    setSelectedDeviceActionConditionGroups([]);
    queryClient.fetchQuery(['deviceActionConditionGroups']);
  };

  useEffect(() => {
    setSelectedDeviceActionConditionGroups([]);
    queryClient.fetchQuery(['deviceActionConditionGroups']);
  }, [props.deviceActionId]);

  if (isBundleLoading) return <Spinner/>;

  const ColumnDefinitions: TableProps.ColumnDefinition<DeviceActionConditionGroupV1>[] = [
    {
      cell: (item: DeviceActionConditionGroupV1) => item.operator,
      editConfig: {
        ariaLabel: 'ConditionGroup',
        editIconAriaLabel: 'editable',
        errorIconAriaLabel: 'ConditionGroup Error',
        editingCell: (item, { currentValue, setValue }) => {
          return(
            <Select
              autoFocus
              expandToViewport
              onChange={({ detail }) => {
                setValue(({ label: detail.selectedOption.label, value: detail.selectedOption.value }));
              }}
              options={[...conditionGroupOperators].map(cgo => ({label: cgo, value: cgo}))}
              ref={selectConditionGroupRef}
              selectedOption={currentValue ?? {
                label: item.operator,
                value: item.operator,
              }}
            />);
        },
      },
      header: bundle.getMessage('condition-group-operator'),
      id: 'operator',
    },
  ];

  return(
    <div id='DeviceActionConditionGroupTablePanel'>
      {showConfirmDelete
      &&
      <Modal
        closeAriaLabel='Close'
        onDismiss={() => setShowConfirmDelete(false)}
        visible={showConfirmDelete}
        size='medium'
        footer={
          <Box float='right'>
            <SpaceBetween direction='horizontal' size='xs'>
              <Button
                onClick={() => setShowConfirmDelete(false)}
                variant='link'
              >
                {bundle.getMessage('no')}
              </Button>
              <Button
                disabled={deleteDeviceActionConditionGroupMutation.isLoading}
                onClick={() => deleteDeviceActionConditionGroups()}
                variant='primary'
              >
                {bundle.getMessage('yes')}
              </Button>
            </SpaceBetween>
          </Box>
        }
        header={`${bundle.getMessage('delete')} (${selectedDeviceActionConditionGroups.length})`}
      >
        {bundle.getMessage('confirm-delete')}
      </Modal>}
      <SpaceBetween direction='vertical' size='m'>
        <Table
          columnDefinitions={ColumnDefinitions}
          empty={
            <>
              {bundle.getMessage('no-condition-groups-found')}
            </>
          }
          header={
            <Header
              counter={`(${deviceActionConditionGroups.length})`}
              info={
                <>
                  {error
                  &&
                  <Alert
                    type='error'
                    dismissible
                    onDismiss={() => setError(undefined)}
                  >
                    {error}
                  </Alert>}
                </>
              }
              actions={
                <SpaceBetween direction='horizontal' size='s'>
                  <Button
                    disabled={
                      queryClient.isFetching({ queryKey: ['deviceActionConditionGroups'] }) > 0
                      || queryClient.getQueryState(['deviceActionConditionGroups'])?.status === 'loading'
                    }
                    iconName='refresh'
                    onClick={refresh}
                  />
                  <Button
                    disabled={selectedDeviceActionConditionGroups.length === 0}
                    onClick={() => setShowConfirmDelete(true)}
                  >
                    {bundle.getMessage('delete')}
                  </Button>
                  <Button
                    disabled={
                      queryClient.isFetching({ queryKey: ['deviceActionConditionGroups'] }) > 0
                      || queryClient.getQueryState(['deviceActionConditionGroups'])?.status === 'loading'
                      || deviceActionConditionGroups.findIndex(dacg => dacg.id === '') !== -1
                    }
                    onClick={() => addDeviceActionConditionGroup()}
                    variant='primary'
                  >
                    {bundle.getMessage('new')}
                  </Button>
                </SpaceBetween>
              }
            >
              Groups
            </Header>
        }
          items={items}
          loading={deviceActionConditionGroupsQuery.isFetching}
          onSelectionChange={event => {
            setSelectedDeviceActionConditionGroups(event.detail.selectedItems);
          }}
          selectionType='single'
          selectedItems={selectedDeviceActionConditionGroups}
          submitEdit={submitEdit}
        />
        {selectedDeviceActionConditionGroups.length === 1
        &&
        <DeviceActionConditionsTablePanel
          forceAwakensState={props.forceAwakensState}
          groupId={selectedDeviceActionConditionGroups[0].id}
        />
        }
      </SpaceBetween>
    </div>
  );
}
