import {
  ActionDeviceTypeEventV1,
  ActionDeviceTypeV1,
  ActionV1,
  CreateActionDeviceTypeEventInputV1,
  ActionDeviceTypeEventForActionDeviceTypeV1,
  DeviceEventV1,
} from 'src/API';
import {
  Alert,
  FormField,
  Header,
  Multiselect,
  SelectProps,
  Spinner,
  Table,
} from '@amzn/awsui-components-react';
import {
  ColumnDefinitions,
  DefaultPageSize,
  TableEmptyState,
  TableNoMatchState,
} from './ActionDeviceTypeEventsTableConfig';
import {
  createActionDeviceTypeEvent,
  deleteActionDeviceTypeEvent,
  listDeviceTypeEvents,
  listActionDeviceTypeEventsForActionDeviceType,
} from './utils';
import React, {
  useEffect,
  useState,
} from 'react';
import {
  useMutation,
  useQuery,
} from 'react-query';
import { ForceAwakensStateInterface } from 'src/stores/app';
import { RouteComponentProps } from 'react-router-dom';
import { State } from '@hookstate/core';
import { useBundle } from '@amzn/react-arb-tools';
import { useCollection } from '@amzn/awsui-collection-hooks';

interface IActionDeviceTypeEventsTablePanelProps extends RouteComponentProps {
  forceAwakensState: State<ForceAwakensStateInterface>;
  action: ActionV1;
  actionDeviceType: ActionDeviceTypeV1;
}

export default function ActionDeviceTypeEventsTablePanel(props: IActionDeviceTypeEventsTablePanelProps) {
  console.log(`ActionDeviceTypeEventsTablePanel() props.action is ${JSON.stringify(props.action)} props.actionDeviceType is ${JSON.stringify(props.actionDeviceType)}`);

  const [actionDeviceTypeEvents, setActionDeviceTypeEvents] = useState<ActionDeviceTypeEventForActionDeviceTypeV1[]>([]);
  const [deviceTypeEvents, setDeviceTypeEvents] = useState<DeviceEventV1[]>([]);
  const [error, setError] = useState<string | undefined>();
  const [selectedDeviceTypeEvents, setSelectedDeviceTypeEvents] = useState<SelectProps.Option[]>([]);

  const [bundle, isBundleLoading] = useBundle('components.ActionsSetup.ActionDeviceTypeEventsTablePanel');

  useQuery<DeviceEventV1[]>(
    ['deviceTypeEvents'],
    () => listDeviceTypeEvents(),
    {
      onError: (error) => {
        setDeviceTypeEvents([]);
        setError(typeof error === 'object' ? JSON.stringify(error) : error as string);
      },
      onSuccess: (data) => setDeviceTypeEvents(data),
      refetchOnWindowFocus: false,
      retry: 3,
    },
  );

  const actionDeviceTypeEventsQuery = useQuery<ActionDeviceTypeEventForActionDeviceTypeV1[]>(
    ['actionDeviceTypeEvents'],
    () => listActionDeviceTypeEventsForActionDeviceType(props.actionDeviceType.id),
    {
      onError: (error) => {
        setError(typeof error === 'object' ? JSON.stringify(error) : error as string);
      },
      onSuccess: (data) => {
        setActionDeviceTypeEvents(data);
        setSelectedDeviceTypeEvents(data.map((deviceEvent) => {
          return { label: deviceEvent.device_event_name, value: deviceEvent.device_event_id };
        })
        .sort((a, b) => {
          return a.label < b.label ? -1 : 1;
        }));
      },
      refetchOnWindowFocus: false,
      retry: 3,
    },
  );

  const createActionDeviceTypeEventMutation = useMutation<ActionDeviceTypeEventV1 | undefined, Error, CreateActionDeviceTypeEventInputV1>(
    async (input: CreateActionDeviceTypeEventInputV1) => {
      return await createActionDeviceTypeEvent(input);
    },
    {
      onSettled: (data, error) => {
        if (error) {
          if (!((error as any).errors[0].path[0] === 'createActionDeviceTypeEventV1'
          && (error as any).errors[0]?.message.startsWith('RDSHttp:{"message":"ERROR: duplicate key value violates unique constraint'))) {
            setError(typeof error === 'object' ? JSON.stringify(error) : error as string);
            return;
          }
        }
        const newActionDeviceTypeEvents = [...actionDeviceTypeEvents];
        if (data) {
          setSelectedDeviceTypeEvents([]);
          newActionDeviceTypeEvents.push({
            __typename: 'ActionDeviceTypeEventForActionDeviceTypeV1',
            action_device_type_id: data.action_device_type_id,
            action_device_type: props.actionDeviceType.action_device_type,
            action_id: props.action.id,
            action_name: props.action.name,
            created: data.created,
            created_by: data.created_by,
            id: data.id,
            device_event_id: data.device_event_id,
            device_event_name: deviceTypeEvents.find(dte => dte.id === data.device_event_id)?.name ?? '',
            device_type_id: props.actionDeviceType.device_type_id,
            device_type_name: '',
            updated: data.updated,
            updated_by: data.updated_by,
          });
          setSelectedDeviceTypeEvents([...newActionDeviceTypeEvents.map(adte => 
            ({
              label: adte.device_event_name,
              value: adte.device_event_id,
            }) as SelectProps.Option
          )]);
        }
        actionDeviceTypeEventsQuery.refetch();
      },
    },
  );

  const deleteActionDeviceTypeEventMutation = useMutation<ActionDeviceTypeEventV1 | undefined, Error, string>(
    async (id: string) => {
      return await deleteActionDeviceTypeEvent(id);
    },
    {
      onSettled: (data, error) => {
        if (error) {
          if (!((error as any).errors[0].path[0] === 'deleteActionDeviceTypeEventV1'
          && (error as any).errors[0]?.message.startsWith("Error invoking method 'get(java.lang.Integer)' in java.util.ArrayList at velocity"))) {
            setError(typeof error === 'object' ? JSON.stringify(error) : error as string);
            return;
          }
        }
        if (data) {
          setSelectedDeviceTypeEvents([]);
          const newActionDeviceTypeEvents = [...actionDeviceTypeEvents.filter(ap => ap.id !== data.id)];
          setSelectedDeviceTypeEvents([...newActionDeviceTypeEvents.map(adte => 
            ({
              label: adte.device_event_name,
              value: adte.device_event_id,
            }) as SelectProps.Option
          )]);
          setSelectedDeviceTypeEvents([...newActionDeviceTypeEvents.map(adte => ({
              label: adte.device_event_name,
              value: adte.device_event_id,
            } as SelectProps.Option)
          )]);
          actionDeviceTypeEventsQuery.refetch();
        }
      },
    },
  );

  const { items, actions } = useCollection(
    actionDeviceTypeEvents.sort((a, b) => {
      return a.device_event_name < b.device_event_name ? -1 : 1;
    }),
    {
      filtering: {
        empty: <TableEmptyState title={isBundleLoading ? 'No Events Found' : bundle.getMessage('no-input-events-found')} />,
        noMatch: <TableNoMatchState onClearFilter={() => actions.setFiltering('')} />
      },
      pagination: { pageSize: DefaultPageSize.pageSize },
      sorting: {},
      selection: { trackBy: 'id' }
    }
  );

  const eventMultiselectHandler = async (selectedOptions: SelectProps.Option[]) => {
    selectedOptions.forEach(async so => {
      const existingActionDeviceTypeEvent = actionDeviceTypeEvents.find(ap => ap.device_event_id === so.value);
      if (!existingActionDeviceTypeEvent || existingActionDeviceTypeEvent.id === '') {
        const createActionParameterInput: CreateActionDeviceTypeEventInputV1 = {
          action_device_type_id: props.actionDeviceType.id,
          device_event_id: so.value!,
          created_by: props.forceAwakensState.username.value!,
        };
        await createActionDeviceTypeEventMutation.mutateAsync(createActionParameterInput);
      }
    });
    if (selectedOptions.length < actionDeviceTypeEvents.length) {
      const unselectedActionParameter = actionDeviceTypeEvents.find(ap => selectedOptions.findIndex(so => so.value === ap.device_event_id) === -1);
      if (unselectedActionParameter) await deleteActionDeviceTypeEventMutation.mutateAsync(unselectedActionParameter.id);
    }
  };

  useEffect(() => {
    setSelectedDeviceTypeEvents([]);
    if (props.actionDeviceType) actionDeviceTypeEventsQuery.refetch();
  }, [props.actionDeviceType]);

  if (isBundleLoading) return <Spinner/>;

  return(
    <div id='ActionEventsTablePanel'>
      {error &&
      <Alert
        dismissible
        onDismiss={() => setError(undefined)}
        type='error'
      >
        {error}
      </Alert>}
      <FormField label={bundle.getMessage('select-input-events')}>
        <Multiselect
          filteringType='auto'
          hideTokens
          onChange={({ detail }) => eventMultiselectHandler([...detail.selectedOptions])}
          options={deviceTypeEvents
            .sort((a, b) => a.name < b.name ? -1 : 1)
            .map(e => ({ label: e.name, value: e.id.toString() }))}
          selectedOptions={selectedDeviceTypeEvents}
        />
      </FormField>
      <Table
        columnDefinitions={ColumnDefinitions}
        header={
          <Header
            counter={`(${items.length})`}
          >
            {bundle.getMessage('events')}
          </Header>
        }
        items={items}
        loading={actionDeviceTypeEventsQuery.isFetching || actionDeviceTypeEventsQuery.isLoading}
      />
    </div>
  );
}
