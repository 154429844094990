import {
  ActionDeviceTypeV1,
  ActionV1,
} from 'src/API';
import {
  Alert,
  AppLayout,
  Box,
  Button,
  FormField,
  Header,
  Input,
  Modal,
  Pagination,
  SpaceBetween,
  Spinner,
  Table,
  TableProps,
  TextFilter,
} from '@amzn/awsui-components-react';
import {
  DefaultPageSize,
  PaginationLabels,
  TableEmptyState,
  TableNoMatchState,
} from './ActionsSetupTableConfig';
import {
  deleteAction,
  listActions,
  saveAction,
} from './utils';
import React, {
  useEffect,
  useState,
} from 'react';
import {
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import { ActionDeviceType } from 'src/constants/Constants';
import ActionDeviceTypesTablePanel from './ActionDeviceTypesTablePanel';
import ActionDeviceEventsTablePanel from './ActionDeviceTypeEventsTablePanel';
import ActionParametersTablePanel from './ActionParametersTablePanel';
import { ForceAwakensConfigNavigation } from '../navigation';
import { ForceAwakensStateInterface } from 'src/stores/app';
import { RouteComponentProps } from 'react-router-dom';
import { State } from '@hookstate/core';
import { useBundle } from '@amzn/react-arb-tools';
import { useCollection } from '@amzn/awsui-collection-hooks';

interface IActionsSetupTablePanelProps extends RouteComponentProps {
  forceAwakensState: State<ForceAwakensStateInterface>;
}

export default function ActionsSetupTablePanel(props: IActionsSetupTablePanelProps) {

  const [actions, setActions] = useState<ActionV1[]>([]);
  const [addingAction, setAddingAction] = useState<boolean>(false);
  const [deleteError, setDeleteError] = useState<string | undefined>();
  const [deletingAction, setDeletingAction] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const [newActionDescription, setNewActionDescription] = useState<string>('');
  const [newActionName, setNewActionName] = useState<string>('');
  const [selectedActions, setSelectedActions] = useState<ActionV1[]>([]);
  const [selectedActionDeviceType, setSelectedActionDeviceType] = useState<ActionDeviceTypeV1>();
  const [showAddAction, setShowAddAction] = useState<boolean>(false);
  const [showDeleteAction, setShowDeleteAction] = useState<boolean>(false);

  const [bundle, isBundleLoading] = useBundle('components.ActionsSetup.ActionsSetupTablePanel');

  const queryClient = useQueryClient();

  const actionsQuery = useQuery<ActionV1[]>(
    ['actions'],
    () => listActions(),
    {
      onError: (error) => {
        setError(typeof error === 'object' ? JSON.stringify(error) : error as string);
      },
      onSuccess: (data) => {
        setActions(data);
      },
      refetchOnWindowFocus: false,
      retry: 3,
    },
  );

  const saveActionSetupMutation = useMutation<ActionV1, Error, ActionV1>(
    async (input: ActionV1) => {
      return await saveAction(input);
    },
    {
      onSettled: (data, error) => {
        if (error) {
          setError(typeof error === 'object' ? JSON.stringify(error) : error as string);
          setNewActionDescription('');
          setNewActionName('');
          setAddingAction(false);
          setShowAddAction(false);
          return;
        }
        const newActions = [...actions.filter(a => a.id !== data?.id)];
        setSelectedActions([]);
        if (data) {
          newActions.push(data);
          setSelectedActions([data]);
        }
        if (actions.findIndex(a => a.id === data?.id) === -1) collectionActions.setCurrentPage(paginationProps.pagesCount);
        setActions([...newActions]);
        setNewActionDescription('');
        setNewActionName('');
        setShowAddAction(false);
        setAddingAction(false);
        setError(undefined);
      },
    },
  );

  const addActionHandler = async () => {
    if (newActionName === '') return;
    setAddingAction(true);
    const newAction: ActionV1 = {
      __typename: 'ActionV1',
      name: newActionName,
      id: '',
      description: newActionDescription,
      created: '',
      created_by: props.forceAwakensState.username.value!,
      updated: '',
      updated_by: props.forceAwakensState.username.value!,
    };
    const action: ActionV1 = {
      __typename: 'ActionV1',
      id: '',
      name: newAction.name,
      description: newAction.description,
      additional_info: undefined,
      created: '',
      created_by: props.forceAwakensState.username.value!,
      updated: '',
      updated_by: ''
    };
    await saveActionSetupMutation.mutateAsync(action);
  };

  const deleteActionMutation = useMutation<ActionV1 | undefined, Error, string>(
    async (id: string) => {
      return await deleteAction(id);
    },
    {
      onError: (error: any) => {
        setDeleteError(typeof error === 'object' ? JSON.stringify(error) : error as string);
      },
      onSettled: (data: ActionV1 | undefined) => {
        const newActions = [...actions.filter(a => a.name !== data?.name)];
        setActions([...newActions]);
        setShowDeleteAction(false);
        setDeletingAction(false);
        setSelectedActions([]);
      },
    },
  );

  const deleteActionHandler = async () => {
    if (!selectedActions[0]) return;
    setDeletingAction(true);
    await deleteActionMutation.mutateAsync(selectedActions[0].id);
  };

  const refreshHandler = () => {
    setSelectedActions([]);
    queryClient.refetchQueries(['actions']);
    queryClient.refetchQueries(['deviceTypeEvents']);
    queryClient.refetchQueries(['deviceTypes']);
    queryClient.refetchQueries(['parameters']); 
  };

  const { items, actions: collectionActions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    actions.sort((a, b) => a.name < b.name ? -1 : 1),
    {
      filtering: {
        empty: <TableEmptyState title={isBundleLoading ? 'No Actions Found' : bundle.getMessage('no-actions-found')} />,
        noMatch: <TableNoMatchState onClearFilter={() => collectionActions.setFiltering('')} />
      },
      pagination: { pageSize: DefaultPageSize.pageSize },
      sorting: {},
      selection: { trackBy: 'id' }
    }
  );

  const getFilterCounterText = (count: number) => `${count} ${count === 1 ? 'match' : 'matches'}`;

  const submitEdit = async (
    currentItem: ActionV1,
    column: TableProps.ColumnDefinition<ActionV1>,
    value: any) =>
  {
    const index = actions.findIndex((v) => v.id === currentItem.id);
    if (index !== -1 && value !== undefined && value !== '') {
      const action: ActionV1 = {
        __typename: 'ActionV1',
        additional_info: undefined,
        created: '',
        created_by: props.forceAwakensState.username.value!,
        description: column.id === 'description' ? value : actions[index].description,
        name: column.id === 'name' ? value : actions[index].name,
        id: actions[index].id,
        updated: '',
        updated_by: props.forceAwakensState.username.value!,
      };
      await saveActionSetupMutation.mutateAsync(action);
    }
  };

  useEffect(() => {
    setSelectedActionDeviceType(undefined);
    queryClient.refetchQueries(['actionDeviceTypeEvents']);
    queryClient.refetchQueries(['actionDeviceTypes']);
    queryClient.refetchQueries(['actionParameters']);
  }, [selectedActions]);

  if (isBundleLoading) return <Spinner/>;

  const ColumnDefinitions: TableProps.ColumnDefinition<ActionV1>[] = [
    {
      cell: (item: { name: any; }) => item.name,
      editConfig: {
        ariaLabel: 'Name',
        disabledReason: () => undefined,
        editIconAriaLabel: 'editable',
        errorIconAriaLabel: 'Name Error',
        editingCell: (item, { currentValue, setValue }) => {
          return(
            <Input
              autoFocus={true}
              onChange={({ detail }) => {
                setValue(detail.value);
              }}
              value={currentValue ?? item.name}
            />);
        },
        validation: (item, value) => {
          if (value === undefined) return undefined;
          if (!value || value.length === 0) {
            return bundle.getMessage('value-required');
          }
          return undefined;
        },
      },
      header: bundle.getMessage('name'),
      id: 'name',
      sortingField: 'name',
    },
    {
      cell: (item) => item.description,
      editConfig: {
        ariaLabel: 'Description',
        disabledReason: () => undefined,
        editIconAriaLabel: 'editable',
        errorIconAriaLabel: 'Description Error',
        editingCell: (item, { currentValue, setValue }) => {
          return(
            <Input
              autoFocus={true}
              onChange={({ detail }) => {
                setValue(detail.value);
              }}
              value={currentValue ?? item.description}
            />);
        },
        validation: (item, value) => {
          if (value === undefined) return undefined;
          if (!value || value.length === 0) {
            return bundle.getMessage('value-required');
          }
          return undefined;
        },
      },
      header: bundle.getMessage('description'),
      id: 'description',
      sortingField: 'description',
    },
  ];

  return(
    <div id='ActionsSetupTablePanel'>
      {showAddAction
      &&
        <Modal
          footer={
            <Box float='right'>
              <SpaceBetween direction='horizontal' size='s'>
                <Button
                  formAction='none'
                  disabled={addingAction}
                  onClick={() => {
                    setNewActionDescription('');
                    setNewActionName('');
                    setShowAddAction(false);
                  }}
                >
                  {bundle.getMessage('cancel')}
                </Button>
                <Button
                  disabled={newActionName === '' || newActionDescription === ''}
                  loading={addingAction}
                  onClick={addActionHandler}
                  variant='primary'
                >
                  {bundle.getMessage('add')}
                </Button>
              </SpaceBetween>
            </Box>
          }
          header={
            <Header>{bundle.getMessage('add')}</Header>
          }
          onDismiss={() => {
            setShowAddAction(false);
            setNewActionDescription('');
            setNewActionName('');
          }}
          visible={showAddAction}
        >
            <FormField label={bundle.getMessage('name')}>
              <Input
                onChange={(event) => setNewActionName(event.detail.value)}
                value={newActionName}
              />
            </FormField>
            <FormField label={bundle.getMessage('description')}>
              <Input
                onChange={
                  (event) => {
                    setNewActionDescription(event.detail.value);
                  }
                }
                value={newActionDescription}
              />
          </FormField>
        </Modal>}
      {showDeleteAction
      &&
      <Modal
        footer={
          <Box float='right'>
           <SpaceBetween direction='horizontal' size='s'>
              <Button
                disabled={deletingAction}
                onClick={() => setShowDeleteAction(false)}
              >
                {bundle.getMessage('cancel')}
              </Button>
              <Button
                loading={deletingAction}
                onClick={deleteActionHandler}
                variant='primary'
              >
                {bundle.getMessage('delete')}
              </Button>
            </SpaceBetween>
          </Box>
        }
        header={
          <>
            <Header>{bundle.getMessage('delete')}</Header>
            {deleteError
            &&
              <Alert
                type='error'
                dismissible
                onDismiss={() => setDeleteError(undefined)}
              >
                {deleteError}
              </Alert>
            }
          </>
        }
        onDismiss={() => {
          setShowDeleteAction(false);
        }}
        visible={showDeleteAction}
      >
        <Header>{selectedActions[0].name}</Header>
      </Modal>}
      <AppLayout
        content={
          <Table
            {...collectionProps}
            empty={error
              ?
                <Alert
                  type='error'
                  dismissible
                  onDismiss={() => setError(undefined)}
                >
                  {error}
                </Alert>
              : bundle.getMessage('no-actions-found')
            }
            filter={
              <TextFilter
                {...filterProps}
                filteringAriaLabel="Filter Actions"
                filteringPlaceholder="Find Actions"
                countText={getFilterCounterText(filteredItemsCount === undefined ? 0: filteredItemsCount)}
              />
            }
            footer={
              selectedActions.length === 1
              &&
              <div>
                <Header>
                  {selectedActions[0].name}: {selectedActions[0].description}
                </Header>
                <SpaceBetween direction='horizontal' size='l'>
                  <ActionParametersTablePanel
                    {...props}
                    action={selectedActions[0]}
                    forceAwakensState={props.forceAwakensState}
                  />
                  <ActionDeviceTypesTablePanel
                    {...props}
                    action={selectedActions[0]}
                    actionDeviceType={ActionDeviceType.input}
                    forceAwakensState={props.forceAwakensState}
                    setSelectedActionDeviceType={setSelectedActionDeviceType}
                  />
                  {selectedActionDeviceType
                  ?
                  <ActionDeviceEventsTablePanel
                    key={'action-events-table-panel'}
                    {...props}
                    forceAwakensState={props.forceAwakensState}
                    action={selectedActions[0]}
                    actionDeviceType={selectedActionDeviceType}
                  />
                  : <div key={'select-device-type'}>{bundle.getMessage('select-input-device-type-for-action-events')}</div>}
                  <ActionDeviceTypesTablePanel
                    {...props}
                    action={selectedActions[0]}
                    actionDeviceType={ActionDeviceType.output}
                    forceAwakensState={props.forceAwakensState}
                    setSelectedActionDeviceType={setSelectedActionDeviceType}
                  />
                </SpaceBetween>
              </div>
            }
            header={
              <>
                {error
                &&
                  <Alert
                    type='error'
                    dismissible
                    onDismiss={() => setError(undefined)}
                  >
                    {error}
                  </Alert>
                }
                <Header
                  counter={`(${items.length})`}
                  actions={
                    <SpaceBetween direction='horizontal' size='s'>
                      <Button
                        onClick={refreshHandler}
                        iconName='refresh'
                      />
                      <Button
                        onClick={() => setShowDeleteAction(true)}
                        disabled={selectedActions?.length !== 1}
                      >
                        {bundle.getMessage('delete')}
                      </Button>
                      <Button
                        onClick={() => setShowAddAction(true)}
                        variant='primary'
                      >
                        {bundle.getMessage('add')}
                      </Button>
                    </SpaceBetween>
                  }
                >
                  Actions
                </Header>
              </>
            }
            items={items}
            columnDefinitions={ColumnDefinitions}
            loading={actionsQuery.isLoading || actionsQuery.isFetching}
            onSelectionChange={({ detail }) => setSelectedActions(detail.selectedItems) }
            pagination={
              <Pagination
                {...paginationProps}
                ariaLabels={PaginationLabels}
              />
            }
            selectedItems={selectedActions}
            selectionType='single'
            submitEdit={submitEdit}
          />
        }
        contentType='table'
        disableContentPaddings
        headerSelector='#topNavigation'
        navigation={<ForceAwakensConfigNavigation forceAwakensState={props.forceAwakensState}/>}
        stickyNotifications
        toolsHide
      />
    </div>
  );
}
