import React, { useEffect, useState } from 'react';
import { useState as useHookState } from '@hookstate/core';
import { getUserNameFromMidway } from '../utils/MidwayAuthUtils';
import Box from "@amzn/awsui-components-react/polaris/box";
import Link from "@amzn/awsui-components-react/polaris/link";
import { forceAwakensBaseState } from '../stores/app';
import AuthorizeUser from './AuthorizeUser';

import { URLS, UserActionNames } from '../constants/Constants';
import { Auth } from 'aws-amplify';
import { Spinner } from '@amzn/awsui-components-react';
import jwtDecode from 'jwt-decode';
import { createUserAction } from 'src/utils/UserActionsUtils';

export default function AuthenticateUser() {
  console.log('AuthenticateUser()');

  const [ authFailed, setAuthFailed ] = useState<boolean>(false);
  const [ authError, setAuthError ] = useState<string>('');
  const [ decodedTokenValue, setDecodedTokenValue ] = useState<any>();

  document.title = "Force Awakens Configuration";

  const forceAwakensState = useHookState(forceAwakensBaseState);

  let username: string | undefined;

  let componentToRender;

  useEffect(() => {
    console.log('AuthenticateUser() useEffect([])');
    setAuthFailed(false);
    Auth.currentAuthenticatedUser()
      .then(async (cognitoUserData: { username: string; signInUserSession: { idToken: { jwtToken: any; }; }; }) => {
        console.log(`AuthenticateUser() useEffect([]) currentAuthenticateUser() cognitoUserData is ${JSON.stringify(cognitoUserData)}`);
        const username = cognitoUserData.username.split('_')[1];
        forceAwakensState.username.set(username);
        console.log(`AuthenticateUser() useEffect([]) currentAuthenticatedUser() username is ${username}`);
        try {
          const jwtToken = cognitoUserData.signInUserSession.idToken.jwtToken;
          const decodedTokenValue: any = jwtDecode(jwtToken);
          console.log(`AuthenticateUser() useEffect([]) currentAuthenticateUser() decodedTokenValue is ${JSON.stringify(decodedTokenValue)}`);
          setDecodedTokenValue(decodedTokenValue);
        } catch(error) {
          console.log(`AuthenticateUser() useEffect([]) currentAuthenticateUser() error is ${error} JSON.stringify: ${JSON.stringify(error)}`);
        }
      })
      .catch((error) => {
        console.log(`AuthenticateUser() useEffect([]) currentAuthenticateUser() error is ${JSON.stringify(error)}`);
        setAuthError(error);
        Auth.federatedSignIn({ customProvider: 'AmazonFederate', customState: window.location.href })
          .catch(error => {
            console.log(`AuthenticateUser() useEffect([]) federatedSignIn() error is ${JSON.stringify(error)}`);
            setAuthFailed(true);
            setAuthError(error);
          });
      });
  }, []);

  if (forceAwakensState.username.value && decodedTokenValue) {
    console.log(`AuthenticateUser() authentication complete, forceAwakensState.username.value is ${forceAwakensState.username.value} decodedTokenValue is ${JSON.stringify(decodedTokenValue)}`);
    createUserAction({ actionName: UserActionNames.AuthenticateUser, username: forceAwakensState.username.value });
    componentToRender = (
      <div>
        <AuthorizeUser forceAwakensState={forceAwakensState} />
      </div>
    );
  } else if (authFailed) {
    console.log(`AuthenticateUser() authFailed`);
    createUserAction(
      {
        actionName: UserActionNames.AuthenticateUserError,
        username: forceAwakensState.username.value,
        parameters: JSON.stringify(
          {
            authError: authError,
          })
      });
    componentToRender = (
      <Box
        margin={{ top: "s", left: "s"}}
        color="inherit"
        display="block"
        fontSize="heading-l"
      >
        Failed to authenticate user, please try again or contact
        <Link external href={URLS.Contact}> SIDE Support</Link>
      </Box>
    );
  } else {
    console.log(`AuthenticateUser() authenticating`);
    componentToRender = (
      <Box
        margin={{ top: "s", left: "s"}}
        color="inherit"
        display="block"
        fontSize="heading-l"
      >
        Authenticating User...<Spinner />
      </Box>
    );
  }

  return componentToRender;
}