import React, { useState } from 'react';
import { useState as useHookState } from '@hookstate/core';
import {
  Button,
  Container,
  Form,
  FormField,
  Select,
  SpaceBetween,
  Spinner
} from '@amzn/awsui-components-react';
import { DeviceLinkEvent } from '../../API';
import API, { GraphQLResult, graphqlOperation } from '@aws-amplify/api';
import * as APIt from "../../API";
import { createUserAction } from 'src/utils/UserActionsUtils';
import { updateDeviceLinkEventV1 as updateDeviceLinkEventMutation } from "../../graphql/mutations";
import { UpdatedDeviceLinkEventInterface } from './ChildTablePanel';
import { CancelUpdateDeviceLinkEventInterface } from './ChildTablePanel';
import { SelectOptionInterface } from './MainContents';
import { forceAwakensBaseState } from 'src/stores/app';
import { useBundle } from '@amzn/react-arb-tools';
import { UserActionNames } from 'src/constants/Constants';
export interface DeviceLinkEventEditPanelPropsInterface {
  cancelCallback: CancelUpdateDeviceLinkEventInterface;
  deviceLinkEvents: DeviceLinkEvent[];
  eventOptions: SelectOptionInterface[];
  saveCallback: UpdatedDeviceLinkEventInterface;
  selectedDeviceLinkEvent: DeviceLinkEvent;
}

export const DeviceLinkEventEditPanel = (props: DeviceLinkEventEditPanelPropsInterface) => {

  const [eventIdValue, setEventIdValue] = useState<string>(() => props.selectedDeviceLinkEvent.event_id);
  const [eventNameValue, setEventNameValue] = useState<string>(() => props.selectedDeviceLinkEvent.event_name);

  const [bundle, isBundleLoading] = useBundle('components.DeviceLinks.ChildEdit');

  const forceAwakensState = useHookState(forceAwakensBaseState);

  const updateDeviceLinkEvent = async () => {
    try {
      const response = await API.graphql(graphqlOperation(updateDeviceLinkEventMutation,
        {
          input:
            {
              id: props.selectedDeviceLinkEvent.id,
              event_id: eventIdValue,
              updated_by: forceAwakensState.username.value
            }
        })) as GraphQLResult<APIt.UpdateDeviceLinkEventV1Mutation>;
      if (response && response.data && response.data.updateDeviceLinkEventV1) {
        createUserAction({
            actionName: UserActionNames.UpdateDeviceLinkEvent,
            username: forceAwakensState.username.value,
            parameters: JSON.stringify(
              {
                device_link_id: props.selectedDeviceLinkEvent.id,
                event_link_name: eventNameValue,
              }
            )
        });
        const updatedDeviceLinkEvent = response.data.updateDeviceLinkEventV1;
        props.saveCallback(updatedDeviceLinkEvent);
      }
    } catch (e) {
      console.log(`updateDeviceLinkEvent(): exception is ${JSON.stringify(e)}`);
      createUserAction({
        actionName: UserActionNames.UpdateDeviceLinkEvError,
        username: forceAwakensState.username.value,
        parameters: JSON.stringify(
          {
            device_link_id: props.selectedDeviceLinkEvent.id,
            event_link_name: eventNameValue,
            updateDeviceLinkEvError: e,
          }
        )
      });
    }
  };

  const cancelBtnHandler = () => {
    props.cancelCallback();
  };

  const saveBtnHandler = () => {
    updateDeviceLinkEvent();
  };

  const deviceLinkEventEventFieldOnChangeHandler = (detail: any) => {
    setEventIdValue(detail.selectedOption.value);
    setEventNameValue(detail.selectedOption.label);
  };

  if (isBundleLoading) return <Spinner/>;

  return (
    <div key={props.selectedDeviceLinkEvent.id}>
      <Container>
        <Form>
          <SpaceBetween size="xs" direction="vertical">
            <FormField label={bundle.getMessage('event')}>
              <Select
                filteringType='auto'
                onChange={({ detail }) => deviceLinkEventEventFieldOnChangeHandler(detail)}
                options={props.eventOptions.filter(eo => props.deviceLinkEvents.findIndex(e => e.event_id === eo.value) === -1)}
                selectedAriaLabel="Selected"
                selectedOption={{ label: eventNameValue, value: eventIdValue }}
              />
            </FormField>
            <SpaceBetween size="xs" direction="horizontal">
              <Button onClick={cancelBtnHandler}>{bundle.getMessage('cancel')}</Button>
              <Button onClick={saveBtnHandler} variant="primary">{bundle.getMessage('save')}</Button>
            </SpaceBetween>
          </SpaceBetween>
        </Form>
      </Container>
    </div>
  );
}