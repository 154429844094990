import API, {
  GraphQLResult,
  graphqlOperation,
} from '@aws-amplify/api';
import {
  ActionDeviceTypeEventForActionDeviceTypeV1,
  ActionDeviceTypeEventV1,
  ActionDeviceTypeV1,
  ActionParameterV1,
  ActionV1,
  CreateActionDeviceTypeEventInputV1,
  CreateActionDeviceTypeEventV1Mutation,
  CreateActionDeviceTypeInputV1,
  CreateActionDeviceTypeV1Mutation,
  CreateActionInputV1,
  CreateActionParameterInputV1,
  CreateActionParameterV1Mutation,
  CreateActionV1Mutation,
  DeleteActionDeviceTypeEventV1Mutation,
  DeleteActionDeviceTypeV1Mutation,
  DeleteActionParameterV1Mutation,
  DeleteActionV1Mutation,
  DeviceEventV1,
  DeviceTypeForActionV1,
  DeviceTypeV1,
  ListActionDeviceTypeEventsForActionDeviceTypeV1Query,
  ListActionDeviceTypeEventsV1Query,
  ListActionDeviceTypesV1Query,
  ListActionsV1Query,
  ListDeviceEventsV1Query,
  ListDeviceTypesForActionV1Query,
  ListDeviceTypesV1Query,
  ListParametersForActionV1Query,
  ListParametersV1Query,
  ParameterForActionV1,
  ParameterV1,
  UpdateActionInputV1,
  UpdateActionV1Mutation,
} from 'src/API';
import {
  createActionDeviceTypeEventV1,
  createActionDeviceTypeV1,
  createActionParameterV1,
  createActionV1,
  deleteActionDeviceTypeEventV1,
  deleteActionDeviceTypeV1,
  deleteActionParameterV1,
  deleteActionV1,
  updateActionV1,
} from 'src/graphql/mutations';
import {
  listActionsV1,
  listActionDeviceTypeEventsForActionDeviceTypeV1,
  listDeviceEventsV1,
  listDeviceTypesForActionV1,
  listDeviceTypesV1,
  listParametersForActionV1,
  listParametersV1,
  listActionDeviceTypeEventsV1,
  listActionDeviceTypesV1,
} from 'src/graphql/queries';

export const listActions = async (): Promise<ActionV1[]> => {
  console.debug(`listActions()`);

  let actions: ActionV1[] = []; 

  try {
    const response = await API.graphql(graphqlOperation(listActionsV1, {})) as GraphQLResult<ListActionsV1Query>;
    console.debug(`listActions() response is ${JSON.stringify(response)}`);
    actions = response.data?.listActionsV1 as ActionV1[] ?? [];
  } catch (error) {
    console.error('listActions() error is', error);
    throw error;
  }

  console.debug('listActions() actions is', actions);
  return actions;
}

export const listDeviceTypeEvents = async (): Promise<DeviceEventV1[]> => {
  console.debug(`listDeviceTypeEvents()`);

  let deviceTypeEvents: DeviceEventV1[] = []; 

  try {
    const response = await API.graphql(graphqlOperation(listDeviceEventsV1, {})) as GraphQLResult<ListDeviceEventsV1Query>;
    console.debug('listDeviceTypeEvents() response is', response);
    deviceTypeEvents = response.data?.listDeviceEventsV1 as DeviceEventV1[] ?? [];
  } catch (error) {
    console.error('listDeviceTypeEvents() error is', error);
    throw error;
  }

  console.debug('listDeviceTypeEvents() deviceTypeEvents is', deviceTypeEvents);
  return deviceTypeEvents;
}

export const listActionDeviceTypes = async (): Promise<ActionDeviceTypeV1[]> => {
  console.debug(`listActionDeviceTypes()`);

  let actionDeviceTypes: ActionDeviceTypeV1[] = []; 

  try {
    const response = await API.graphql(graphqlOperation(listActionDeviceTypesV1, {})) as GraphQLResult<ListActionDeviceTypesV1Query>;
    console.debug(`listActionDeviceTypes() response is ${JSON.stringify(response)}`);
    actionDeviceTypes = response.data?.listActionDeviceTypesV1 as ActionDeviceTypeV1[] ?? [];
  } catch (error) {
    console.error('listActionDeviceTypes() error is', error);
    throw error;
  }

  console.debug('listActionDeviceTypes() deviceTypeEvents is', actionDeviceTypes);
  return actionDeviceTypes;
}

export const listActionDeviceTypeEvents = async (): Promise<ActionDeviceTypeEventV1[]> => {
  console.debug(`listActionDeviceTypeEvents()`);

  let actionDeviceTypeEvents: ActionDeviceTypeEventV1[] = []; 

  try {
    const response = await API.graphql(graphqlOperation(listActionDeviceTypeEventsV1, {})) as GraphQLResult<ListActionDeviceTypeEventsV1Query>;
    console.debug(`listActionDeviceTypeEvents() response is ${JSON.stringify(response)}`);
    actionDeviceTypeEvents= response.data?.listActionDeviceTypeEventsV1 as ActionDeviceTypeEventV1[] ?? [];
  } catch (error) {
    console.error('listActionDeviceTypeEvents() error is', error);
    throw error;
  }

  console.debug('listActionDeviceTypeEvents() actionDeviceTypeEventsis', actionDeviceTypeEvents);
  return actionDeviceTypeEvents;
}

export const listActionDeviceTypeEventsForActionDeviceType = async (actionDeviceTypeId: string | undefined): Promise<ActionDeviceTypeEventForActionDeviceTypeV1[]> => {
  console.debug(`listActionDeviceTypeEventsForActionDeviceType() actionDeviceTypeId is ${actionDeviceTypeId}`);

  let actionDeviceTypeEventsForDeviceType: ActionDeviceTypeEventForActionDeviceTypeV1[] = []; 

  if (!actionDeviceTypeId) return actionDeviceTypeEventsForDeviceType;

  try {
    const response = await API.graphql(graphqlOperation(listActionDeviceTypeEventsForActionDeviceTypeV1, {
      action_device_type_id: actionDeviceTypeId,
    })) as GraphQLResult<ListActionDeviceTypeEventsForActionDeviceTypeV1Query>;
    console.debug(`listActionDeviceTypeEventsForActionDeviceType() response is ${JSON.stringify(response)}`);
    actionDeviceTypeEventsForDeviceType= response.data?.listActionDeviceTypeEventsForActionDeviceTypeV1 as ActionDeviceTypeEventForActionDeviceTypeV1[] ?? [];
  } catch (error) {
    console.error('listActionDeviceTypeEventsForActionDeviceType() error is', error);
    throw error;
  }

  console.debug('listActionDeviceTypeEventsForActionDeviceType() actionDeviceTypeEventsForDeviceType is', actionDeviceTypeEventsForDeviceType);
  return actionDeviceTypeEventsForDeviceType;
}

export const listDeviceTypes = async (): Promise<DeviceTypeV1[]> => {
  console.debug(`listDeviceTypes()`);

  let deviceTypes: DeviceTypeV1[] = []; 

  try {
    const response = await API.graphql(graphqlOperation(listDeviceTypesV1, {})) as GraphQLResult<ListDeviceTypesV1Query>;
    console.debug('listDeviceTypes() response is', response);
    deviceTypes = response.data?.listDeviceTypesV1 as DeviceTypeV1[] ?? [];
  } catch (error) {
    console.error('listDeviceTypes() error is', error);
    throw error;
  }

  console.debug('listDeviceTypes() deviceTypes is', deviceTypes);
  return deviceTypes;
}

export const listDeviceTypesForAction = async (actionId: string): Promise<DeviceTypeForActionV1[]> => {
  console.debug(`listDeviceTypesForAction() actionId is ${actionId}`);

  let deviceTypesForAction: DeviceTypeForActionV1[] = []; 

  if (!actionId) return deviceTypesForAction;

  try {
    const response = await API.graphql(graphqlOperation(listDeviceTypesForActionV1, {
      action_id: actionId,
    })) as GraphQLResult<ListDeviceTypesForActionV1Query>;
    console.debug(`listDeviceTypesForAction() response is ${JSON.stringify(response)}`);
    deviceTypesForAction = response.data?.listDeviceTypesForActionV1 as DeviceTypeForActionV1[] ?? [];
  } catch (error) {
    console.error('listDeviceTypesForAction() error is', error);
    throw error;
  }

  console.debug('listDeviceTypesForAction() deviceTypesForAction is', deviceTypesForAction);
  return deviceTypesForAction;
}

export const listParameters = async (): Promise<ParameterV1[]> => {
  console.debug(`listParameters()`);

  let parameters: ParameterV1[] = []; 

  try {
    const response = await API.graphql(graphqlOperation(listParametersV1, {})) as GraphQLResult<ListParametersV1Query>;
    console.debug(`listParameters() response is ${JSON.stringify(response)}`);
    parameters = response.data?.listParametersV1 as ParameterV1[] ?? [];
  } catch (error) {
    console.error('listParameters() error is', error);
    throw error;
  }

  console.debug('listParameters() parameters is', parameters);
  return parameters;
}

export const listParametersForAction = async (actionId: string): Promise<ParameterForActionV1[]> => {
  console.debug(`listParametersForAction() actionId is ${actionId}`);

  let parametersForAction: ParameterForActionV1[] = []; 

  if (!actionId) return parametersForAction;

  try {
    const response = await API.graphql(graphqlOperation(listParametersForActionV1, {
      action_id: actionId,
    })) as GraphQLResult<ListParametersForActionV1Query>;
    console.debug(`listParametersForAction() response is ${JSON.stringify(response)}`);
    parametersForAction = response.data?.listParametersForActionV1 as ParameterForActionV1[] ?? [];
  } catch (error) {
    console.error('listParametersForAction() error is', error);
    throw error;
  }

  console.debug('listParametersForAction() parametersForAction is', parametersForAction);
  return parametersForAction;
}

export const saveAction = async (action: ActionV1): Promise<ActionV1> => {
  console.debug(`saveAction() action is ${JSON.stringify(action)}`);

  try {
    if (action.id === '') {
      const createActionInput: CreateActionInputV1 = {
        created_by: action.created_by, 
        description: action.description,
        name: action.name,
      };
      const createdAction = await createAction(createActionInput);
      return({
        ...action,
        ...createdAction,
      });
    }
    if (action.id !== '') {
      const updateActionInput: UpdateActionInputV1 = {
        description: action.description,
        id: action.id,
        name: action.name,
        updated_by: action.created_by,
      };
      const updatedAction = await updateAction(updateActionInput);
      return({
        ...action,
        ...updatedAction,
      });
    }
  } catch(error) {
    console.error('saveAction() error is', error);
    throw error;
  }

  return(action);
}

export const createAction = async (createActionInput: CreateActionInputV1): Promise<ActionV1 | undefined> => {
  console.debug(`createAction() createActionInput is ${JSON.stringify(createActionInput)}`);

  let createdAction: ActionV1 | undefined = undefined;

  try {
    const response = await API.graphql(graphqlOperation(createActionV1, {
      input: createActionInput,
    })) as GraphQLResult<CreateActionV1Mutation>;
    console.debug(`createAction() response is ${JSON.stringify(response)}`);
    createdAction = response.data?.createActionV1 as ActionV1;
  } catch (error) {
    console.error('createAction() error is', error);
    throw error;
  }

  console.debug('createAction() createdAction is', createdAction);
  return createdAction;
}

export const updateAction = async (updateActionInput: UpdateActionInputV1): Promise<ActionV1 | undefined> => {
  console.debug(`updateAction() updateActionInput is ${JSON.stringify(updateActionInput)}`);

  let updatedAction: ActionV1 | undefined = undefined;

  try {
    const response = await API.graphql(graphqlOperation(updateActionV1, {
      input: updateActionInput,
    })) as GraphQLResult<UpdateActionV1Mutation>;
    console.debug(`updateAction() response is ${JSON.stringify(response)}`);
    updatedAction = response.data?.updateActionV1 as ActionV1;
  } catch (error) {
    console.error('updateAction() error is', error);
    throw error;
  }

  console.debug('updateAction() updatedAction is', updatedAction);
  return updatedAction;
}

export const deleteAction = async (id: string): Promise<ActionV1 | undefined> => {
  console.debug(`deleteAction() id is ${JSON.stringify(id)}`);

  let deletedAction: ActionV1 | undefined = undefined;

  if (!id || id === '') return deletedAction;

  try {
    const response = await API.graphql(graphqlOperation(deleteActionV1, {
      id,
    })) as GraphQLResult<DeleteActionV1Mutation>;
    console.debug(`deleteAction() response is ${JSON.stringify(response)}`);
    deletedAction = response.data?.deleteActionV1 as ActionV1;
  } catch (error) {
    console.error('deleteAction() error is', error);
    throw error;
  }

  console.debug('deleteAction() deletedAction is', deletedAction);
  return deletedAction;
}

export const createActionParameter = async (createActionParameterInput: CreateActionParameterInputV1): Promise<ActionParameterV1 | undefined> => {
  console.debug(`createActionParameter() createActionParameterInput is ${JSON.stringify(createActionParameterInput)}`);

  let createdActionParameter: ActionParameterV1 | undefined = undefined;

  try {
    const response = await API.graphql(graphqlOperation(createActionParameterV1, {
      input: createActionParameterInput,
    })) as GraphQLResult<CreateActionParameterV1Mutation>;
    console.debug(`createActionParameter() response is ${JSON.stringify(response)}`);
    createdActionParameter = response.data?.createActionParameterV1 as ActionParameterV1;
  } catch (error) {
    console.error('createActionParameter() error is', error);
    throw error;
  }

  console.debug('createActionParameter() createdActionParameter is', createdActionParameter);
  return createdActionParameter;
}

export const deleteActionParameter = async (id: string): Promise<ActionParameterV1 | undefined> => {
  console.debug(`deleteActionParameter() id is ${JSON.stringify(id)}`);

  let deletedActionParameter: ActionParameterV1 | undefined = undefined;

  if (!id || id === '') return deletedActionParameter;

  try {
    const response = await API.graphql(graphqlOperation(deleteActionParameterV1, {
      id,
    })) as GraphQLResult<DeleteActionParameterV1Mutation>;
    console.debug(`deleteActionParameter() response is ${JSON.stringify(response)}`);
    deletedActionParameter = response.data?.deleteActionParameterV1 as ActionParameterV1;
  } catch (error) {
    console.error('deleteActionParameter() error is', error);
    throw error;
  }

  console.debug('deleteActionParameter() deletedActionParameter is', deletedActionParameter);
  return deletedActionParameter;
}

export const createActionDeviceType = async (createActionDeviceTypeInput: CreateActionDeviceTypeInputV1): Promise<ActionDeviceTypeV1 | undefined> => {
  console.debug(`createActionDeviceType() createActionDeviceTypeInput is ${JSON.stringify(createActionDeviceTypeInput)}`);

  let createdActionDeviceType: ActionDeviceTypeV1 | undefined = undefined;

  try {
    const response = await API.graphql(graphqlOperation(createActionDeviceTypeV1, {
      input: createActionDeviceTypeInput,
    })) as GraphQLResult<CreateActionDeviceTypeV1Mutation>;
    console.debug(`createActionDeviceType() response is ${JSON.stringify(response)}`);
    createdActionDeviceType = response.data?.createActionDeviceTypeV1 as ActionDeviceTypeV1;
  } catch (error) {
    console.error('createActionDeviceType() error is', error);
    throw error;
  }

  console.debug('createActionDeviceType() createdActionDeviceType is', createdActionDeviceType);
  return createdActionDeviceType;
}

export const deleteActionDeviceType = async (id: string): Promise<ActionDeviceTypeV1 | undefined> => {
  console.debug(`deleteActionDeviceType() id is ${JSON.stringify(id)}`);

  let deletedActionDeviceType: ActionDeviceTypeV1 | undefined = undefined;

  if (!id || id === '') return deletedActionDeviceType;

  try {
    const response = await API.graphql(graphqlOperation(deleteActionDeviceTypeV1, {
      id,
    })) as GraphQLResult<DeleteActionDeviceTypeV1Mutation>;
    console.debug(`deleteActionDeviceType() response is ${JSON.stringify(response)}`);
    deletedActionDeviceType = response.data?.deleteActionDeviceTypeV1 as ActionDeviceTypeV1;
  } catch (error) {
    console.error('deleteActionDeviceType() error is', error);
    throw error;
  }

  console.debug('deleteActionDeviceType() deletedActionParameterType is', deletedActionDeviceType);
  return deletedActionDeviceType;
}

export const createActionDeviceTypeEvent = async (createActionDeviceTypeEventInput: CreateActionDeviceTypeEventInputV1): Promise<ActionDeviceTypeEventV1 | undefined> => {
  console.debug(`createActionDeviceTypeEvent() createActionDeviceTypeEventInput is ${JSON.stringify(createActionDeviceTypeEventInput)}`);

  let createdActionDeviceTypeEvent: ActionDeviceTypeEventV1 | undefined = undefined;

  try {
    const response = await API.graphql(graphqlOperation(createActionDeviceTypeEventV1, {
      input: createActionDeviceTypeEventInput,
    })) as GraphQLResult<CreateActionDeviceTypeEventV1Mutation>;
    console.debug(`createActionDeviceType() response is ${JSON.stringify(response)}`);
    createdActionDeviceTypeEvent = response.data?.createActionDeviceTypeEventV1 as ActionDeviceTypeEventV1;
  } catch (error) {
    console.error('createActionDeviceTypeEvent() error is', error);
    throw error;
  }

  console.debug('createActionDeviceTypeEvent() createdActionDeviceTypeEvent is', createdActionDeviceTypeEvent);
  return createdActionDeviceTypeEvent;
}

export const deleteActionDeviceTypeEvent = async (id: string): Promise<ActionDeviceTypeEventV1 | undefined> => {
  console.debug(`deleteActionDeviceTypeEvent() id is ${JSON.stringify(id)}`);

  let deletedActionDeviceTypeEvent: ActionDeviceTypeEventV1 | undefined = undefined;

  if (!id || id === '') return deletedActionDeviceTypeEvent;

  try {
    const response = await API.graphql(graphqlOperation(deleteActionDeviceTypeEventV1, {
      id,
    })) as GraphQLResult<DeleteActionDeviceTypeEventV1Mutation>;
    console.debug(`deleteActionDeviceTypeEvent() response is ${JSON.stringify(response)}`);
    deletedActionDeviceTypeEvent = response.data?.deleteActionDeviceTypeEventV1 as ActionDeviceTypeEventV1;
  } catch (error) {
    console.error('deleteActionDeviceTypeEvent() error is', error);
    throw error;
  }

  console.debug('deleteActionDeviceTypeEvent() deletedActionParameterTypeEvent is', deletedActionDeviceTypeEvent);
  return deletedActionDeviceTypeEvent;
}

export const debounce = <T extends (...args: any[]) => void>(
  func: T,
  delay: number
) => {
  let timeoutId: NodeJS.Timeout;

  return (...args: Parameters<T>) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};
