import {
  ActionParameterV1,
  ActionV1,
  CreateActionParameterInputV1,
  ParameterForActionV1,
  ParameterV1,
} from 'src/API';
import {
  Alert,
  FormField,
  Header,
  Multiselect,
  SelectProps,
  Spinner,
  Table,
} from '@amzn/awsui-components-react';
import {
  ColumnDefinitions,
  DefaultPageSize,
  TableEmptyState,
  TableNoMatchState,
} from './ActionParametersTableConfig';
import {
  createActionParameter,
  deleteActionParameter,
  listParameters,
  listParametersForAction,
} from './utils';
import React, {
  useEffect,
  useState,
} from 'react';
import {
  useMutation,
  useQuery,
} from 'react-query';
import { ForceAwakensStateInterface } from 'src/stores/app';
import { RouteComponentProps } from 'react-router-dom';
import { State } from '@hookstate/core';
import { useBundle } from '@amzn/react-arb-tools';
import { useCollection } from '@amzn/awsui-collection-hooks';

interface IActionParametersTablePanelProps extends RouteComponentProps {
  forceAwakensState: State<ForceAwakensStateInterface>;
  action: ActionV1;
}

export default function ActionParametersTablePanel(props: IActionParametersTablePanelProps) {
  console.log(`ActionParametersTablePanel() props.action is ${JSON.stringify(props.action)}`);

  const [actionParameters, setActionParameters] = useState<ParameterForActionV1[]>([]);
  const [parameters, setParameters] = useState<ParameterV1[]>([]);
  const [error, setError] = useState<string | undefined>();
  const [selectedParameters, setSelectedParameters] = useState<SelectProps.Option[]>([]);

  const [bundle, isBundleLoading] = useBundle('components.ActionsSetup.ActionParametersTablePanel');

  useQuery<ParameterV1[]>(
    ['parameters'],
    () => listParameters(),
    {
      onError: (error) => {
        setParameters([]);
        setError(typeof error === 'object' ? JSON.stringify(error) : error as string);
      },
      onSuccess: (data) => setParameters(data),
      refetchOnWindowFocus: false,
      retry: 3,
    },
  );

  const actionParametersQuery = useQuery<ParameterForActionV1[]>(
    ['actionParameters'],
    () => listParametersForAction(props.action.id),
    {
      onError: (error) => {
        setError(typeof error === 'object' ? JSON.stringify(error) : error as string);
      },
      onSuccess: (data) => {
        setActionParameters(data);
        setSelectedParameters(data.map((parameter) => {
          return { label: parameter.parameter_name, value: parameter.parameter_id };
        })
      .sort((a, b) => {
        return a.label < b.label ? -1 : 1
      }));
      },
      refetchOnWindowFocus: false,
      retry: 3,
    },
  );

  const createActionParameterMutation = useMutation<ActionParameterV1 | undefined, Error, CreateActionParameterInputV1>(
    async (input: CreateActionParameterInputV1) => {
      return await createActionParameter(input);
    },
    {
      onSettled: (data, error) => {
        if (error) {
          if (!((error as any).errors[0].path[0] === 'createActionParameterV1'
          && (error as any).errors[0]?.message.startsWith('RDSHttp:{"message":"ERROR: duplicate key value violates unique constraint'))) {
            setError(typeof error === 'object' ? JSON.stringify(error) : error as string);
            return;
          }
        }
        const newActionParameters = [...actionParameters];
        if (data) {
          setSelectedParameters([]);
          newActionParameters.push({
            __typename: 'ParameterForActionV1',
            action_id: props.action.id,
            action_name: props.action.name,
            created: data.created_by,
            created_by: props.forceAwakensState.username.value!,
            id: data.id,
            parameter_id: data.parameter_id,
            parameter_name: parameters.find(p => p.id === data.parameter_id)!.name,
            updated: data.updated_by,
            updated_by: props.forceAwakensState.username.value!,
          });
          setSelectedParameters([...newActionParameters.map(sp => 
            ({
              label: sp.parameter_name,
              value: sp.parameter_id,
            }) as SelectProps.Option
          )]);
        }
        actionParametersQuery.refetch();
      },
    },
  );

  const deleteActionParameterMutation = useMutation<ActionParameterV1 | undefined, Error, string>(
    async (id: string) => {
      return await deleteActionParameter(id);
    },
    {
      onSettled: (data, error) => {
        if (error) {
          if (!((error as any).errors[0].path[0] === 'deleteActionParameterV1'
          && (error as any).errors[0]?.message.startsWith("Error invoking method 'get(java.lang.Integer)' in java.util.ArrayList at velocity"))) {
            setError(typeof error === 'object' ? JSON.stringify(error) : error as string);
            return;
          }
        }
        if (data) {
          setSelectedParameters([]);
          const newActionParameters = [...actionParameters.filter(ap => ap.id !== data.id)];
          setSelectedParameters([...newActionParameters.map(sp => 
            ({
              label: sp.parameter_name,
              value: sp.parameter_id,
            }) as SelectProps.Option
          )]);
          actionParametersQuery.refetch();
        }
      },
    },
  );

  const { items, actions } = useCollection(
    actionParameters.sort((a, b) => {
      return a.parameter_name < b.parameter_name ? -1 : 1
    }),
    {
      filtering: {
        empty: <TableEmptyState title={isBundleLoading ? 'No Device Types Found' : bundle.getMessage('no-parameters-found')} />,
        noMatch: <TableNoMatchState onClearFilter={() => actions.setFiltering('')} />
      },
      pagination: { pageSize: DefaultPageSize.pageSize },
      sorting: {},
      selection: { trackBy: 'id' }
    }
  );

  const parameterMultiselectHandler = async (selectedOptions: SelectProps.Option[]) => {
    selectedOptions.forEach(async so => {
      const existingActionParameter = actionParameters.find(ap => ap.parameter_id === so.value);
      if (!existingActionParameter || existingActionParameter.id === '') {
        const createActionParameterInput: CreateActionParameterInputV1 = {
          action_id: props.action.id,
          parameter_id: so.value!,
          created_by: props.forceAwakensState.username.value!,
        };
        await createActionParameterMutation.mutateAsync(createActionParameterInput);
      }
    });
    if (selectedOptions.length < actionParameters.length) {
      const unselectedActionParameter = actionParameters.find(ap => selectedOptions.findIndex(so => so.value === ap.parameter_id) === -1);
      if (unselectedActionParameter) await deleteActionParameterMutation.mutateAsync(unselectedActionParameter.id);
    }
  };

  useEffect(() => {
    if (props.action) actionParametersQuery.refetch();
  }, [props.action]);

  if (isBundleLoading) return <Spinner/>;

  return(
    <div id='ActionParametersTablePanel'>
      {error &&
      <Alert
        dismissible
        onDismiss={() => setError(undefined)}
        type='error'
      >
        {error}
      </Alert>}
      <FormField label={bundle.getMessage('select-parameters')}>
        <Multiselect
          filteringType='auto'
          hideTokens
          onChange={({ detail }) => parameterMultiselectHandler([...detail.selectedOptions])}
          options={parameters
            .sort((a, b) => a.name < b.name ? -1 : 1)
            .map(dt => ({ label: dt.name, value: dt.id.toString() }))}
          selectedOptions={selectedParameters}
        />
      </FormField>
      <Table
        columnDefinitions={ColumnDefinitions}
        header={
          <Header
            counter={`(${items.length})`}
          >
            {bundle.getMessage('parameters')}
          </Header>
        }
        items={items}
        loading={actionParametersQuery.isFetching || actionParametersQuery.isLoading}
      />
    </div>
  );
}
