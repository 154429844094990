import { LocalizationContextBuilder } from "@amzn/arb-tools";

export const AUTHENTICATION_MODES = [
  { label: 'BASIC', value: 'BASIC'},
  { label: 'NTLM', value: 'NTLM'},
  { label: 'NTLM_OR_BASIC', value: 'NTLM_OR_BASIC'},
];

export const DEFAULT_LOCALE = navigator.language || 'en-US';
export const DEFAULT_LC_BUILDER = new LocalizationContextBuilder().withDefaultLocale(DEFAULT_LOCALE);

export interface ILanguage {
  id: string;
  text: string;
}
export const Languages: ILanguage[] = [
  {
    id: "en-US",
    text: "English",
  },
  {
    id: "fr-FR",
    text: "French",
  },
  {
    id: "de-DE",
    text: "German",
  },
  {
    id: "es-ES",
    text: "Spanish",
  },
  {
    id: "pt-PT",
    text: "Portuguese",
  },
  {
    id: "it-IT",
    text: "Italian",
  },
  {
    id: "hi-IN",
    text: "Hindi",
  },
  {
    id: "ja-JP",
    text: "Japanese",
  },
  {
    id: "ar-AE",
    text: "Arabic (U.A.E.)",
  },
  {
    id: "ar-SA",
    text: "Arabic (Saudi Arabia)",
  },
  {
    id: "cs-CZ",
    text: "Czech (Czech Republic)",
  },
  {
    id: "en-AU",
    text: "English (Australia)",
  },
  {
    id: "en-CA",
    text: "English (Canada)",
  },
  {
    id: "en-GB",
    text: "English (United Kingdom)",
  },
  {
    id: "en-SG",
    text: "English (Singapore)",
  },
  {
    id: "es-MX",
    text: "Spanish (Mexico)",
  },
  {
    id: "fr-CA",
    text: "French (Canada)",
  },
  {
    id: "nl-NL",
    text: "Dutch (Netherlands)",
  },
  {
    id: "pl-PL",
    text: "Polish (Poland)",
  },
  {
    id: "sk-SK",
    text: "Slovak (Slovakia)",
  },
  {
    id: "tr-TR",
    text: "Turkish (Turkey)",
  },
  {
    id: "zh-CN",
    text: "Chinese (S)",
  },
  {
    id: "zh-TW",
    text: "Chinese (T)",
  },
];

export enum ActionDeviceType {
  input = 'INPUT',
  output = 'OUTPUT',
};

export enum Images {
  home = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAUGVYSWZNTQAqAAAACAACARIAAwAAAAEAAQAAh2kABAAAAAEAAAAmAAAAAAADoAEAAwAAAAEAAQAAoAIABAAAAAEAAAAgoAMABAAAAAEAAAAgAAAAAL5bTO0AAAIwaVRYdFhNTDpjb20uYWRvYmUueG1wAAAAAAA8eDp4bXBtZXRhIHhtbG5zOng9ImFkb2JlOm5zOm1ldGEvIiB4OnhtcHRrPSJYTVAgQ29yZSA2LjAuMCI+CiAgIDxyZGY6UkRGIHhtbG5zOnJkZj0iaHR0cDovL3d3dy53My5vcmcvMTk5OS8wMi8yMi1yZGYtc3ludGF4LW5zIyI+CiAgICAgIDxyZGY6RGVzY3JpcHRpb24gcmRmOmFib3V0PSIiCiAgICAgICAgICAgIHhtbG5zOmV4aWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20vZXhpZi8xLjAvIgogICAgICAgICAgICB4bWxuczp0aWZmPSJodHRwOi8vbnMuYWRvYmUuY29tL3RpZmYvMS4wLyI+CiAgICAgICAgIDxleGlmOlBpeGVsWURpbWVuc2lvbj4zMjwvZXhpZjpQaXhlbFlEaW1lbnNpb24+CiAgICAgICAgIDxleGlmOkNvbG9yU3BhY2U+MTwvZXhpZjpDb2xvclNwYWNlPgogICAgICAgICA8ZXhpZjpQaXhlbFhEaW1lbnNpb24+MzI8L2V4aWY6UGl4ZWxYRGltZW5zaW9uPgogICAgICAgICA8dGlmZjpPcmllbnRhdGlvbj4xPC90aWZmOk9yaWVudGF0aW9uPgogICAgICA8L3JkZjpEZXNjcmlwdGlvbj4KICAgPC9yZGY6UkRGPgo8L3g6eG1wbWV0YT4K0s0j6AAAAYRJREFUWAntlrtKxEAUhjdbKGzrBbFYa6tF0EpB8BEsxCcQa0FhOytfQVCs7Be0t9BaREHRQmwVbQRveIH4DcRATiabmfEsiOTAR5KZc/7/Z3bZbK1WVeAJxHHcgN2ERqBM2BimLbiGnzL3rTA1jylMmrADnyDrg4UtaHpIlrciWIdZ2AabMcuZMkE2YQaiModcA0ODDI0nTHKdhwEIqQeGOnAMV3AeRdEj17RsAeJ0twc3BMh41nvg4SVZBahO4E+ewIvX19iv+VW2207gXjYpPue0bQFuFQ2l1J1csAW4kE2KzydSyxbgQDYpPh9KrczvstnkZTTEJfdZycGA5y9mRnkVmBdUWrkTSBr20w69m440L5TmFOZAu6YLDW0buB8pJtizeXRdw3wMnhVCPKEx3NWsaJPBRYUAC0X6TusEaP8ixJqTSVkTAZbh3SPIG71LZbpe+whOwY1DiEt6JrzEXZsR7ocNsP09Nye0Dn2uesF9mIzAKpzBKaxA2Dc9OMV/GfwGoh25X3xxeygAAAAASUVORK5CYII=',
};

export const MIDWAY = {
  URL: 'https://midway-auth.amazon.com/SSO',
  SCOPE: 'openid',
  RESPONSE_TYPE: 'id_token',
  SENTRY_HANDLER_VERSION: 'MidwayNginxModule-1.3-1'
};

export const URLS = {
  Contact: 'https://w.amazon.com/index.php/SIDE#HContactus',
  GuardianHub: 'https://guardian.gso.amazon.dev/',
  RiverGuide: 'https://river.amazon.com/?org=AccSys&q0=605c02a4-4e1e-49d1-96a4-5e9b5017a1bf',
  SiteNotFound: 'https://w.amazon.com/bin/view/SOT_Knowledge_Center/Video_Systems/Integrations/ForceAwakens/#HNotseeingthesitesyouexpect3F',
  UserGuide: 'https://w.amazon.com/bin/view/SOT_Knowledge_Center/Video_Systems/Integrations/ForceAwakens/',
};

export enum UserActionNames {
  AuthenticateUser = "Authenticate User",
  AuthenticateUserError = "Error: Authenticate User",
  AuditDataSearch = "Audit Data Search",
  AuditDataSearchError = "Audit Data Search Error",
  CreateDeviceLink = "Create Device Link",
  CreateDeviceLinkError = "Create Device Link Error",
  CreateDeviceLinkEvent = "Create Device Link Event",
  CreateDeviceLinkEvError = "Create Device Link Event Error",
  CreateSystem = "Create System",
  CreateSystemError = "Create System Error",
  DeleteDeviceLink = "Delete Device Link",
  DeleteDeviceLinkError = "Delete Device Link Error",
  DeleteDeviceLinkEvent = "Delete Device Link Event",
  DeleteDeviceLinkEvError = "Delete Device Link Event Error",
  SelectSite = "Select Site",
  UpdateDeviceLink = "Update Device Link",
  UpdateDeviceLinkError = "Update Device Link Error",
  UpdateDeviceLinkEvent = "Update Device Link Event",
  UpdateDeviceLinkEvError = "Update Device Link Event Error",
  UpdateSystem = "Update System",
  UpdateSystemError = "Update System Error",
  UIModeChange =  "UI Mode Change",
};
