import React, { useState, useEffect } from 'react';
import { useState as useHookState } from '@hookstate/core';
import {
  Box,
  Button,
  Checkbox,
  CollectionPreferences,
  Container,
  ContentLayout,
  Form,
  FormField,
  Grid,
  Header,
  Pagination,
  Popover,
  Select,
  SpaceBetween,
  Spinner,
  Table,
  TextFilter,
  Toggle
} from '@amzn/awsui-components-react';
import { DeviceLinkV2 } from '../../API';
import API, { GraphQLResult, graphqlOperation } from '@aws-amplify/api';
import * as APIt from "../../API";
import { updateDeviceLinkV1 as updateDeviceLinkMutation } from "../../graphql/mutations";
import { UpdatedDeviceLinkInterface } from './TablePanel';
import { CancelUpdateDeviceLinkInterface } from './TablePanel';
import { SelectOptionInterface } from './MainContents';
import { forceAwakensBaseState } from 'src/stores/app';
import { useBundle } from '@amzn/react-arb-tools';
import { createUserAction } from 'src/utils/UserActionsUtils';
import { UserActionNames } from 'src/constants/Constants';
import { IconContext } from 'react-icons';
import { IoVideocam } from 'react-icons/io5';
import { PaginationLabels, TableEmptyState, TableNoMatchState } from './table-config';
import { FetchCameraImage } from 'src/utils/FetchCameraImage';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { fetchPageSize } from 'src/utils/UserPreferences';
import { SiGooglemaps } from 'react-icons/si';
import { useDoraClients } from 'src/hooks/useDoraClients';

export interface DeviceLinkEditPanelPropsInterface {
  cancelCallback: CancelUpdateDeviceLinkInterface;
  acpDeviceOptions: SelectOptionInterface[];
  acpDeviceOptionsLoading: boolean;
  cameraDeviceOptions: SelectOptionInterface[];
  cameraDeviceOptionsLoading: boolean;
  deviceLinks: APIt.DeviceLinkV2[];
  refreshDeviceOptions: Function;
  refreshCameraOptions: Function;
  saveCallback: UpdatedDeviceLinkInterface;
  selectedDeviceLink: DeviceLinkV2;
  systemOptions: SelectOptionInterface[];
}

export const DeviceLinkEditPanel = (props: DeviceLinkEditPanelPropsInterface) => {

  const [acdDeviceName, setAcdDeviceNameValue] = useState<string>(props.selectedDeviceLink.acd_device_name);
  const [acdDeviceOptions, setACDDeviceOptions] = useState<SelectOptionInterface[]>(props.acpDeviceOptions);
  const [acdDeviceType, setAcdDeviceTypeValue] = useState<string>(props.selectedDeviceLink.acd_device_type);
  const [acdParentDeviceId, setAcdParentDeviceIdValue] = useState<string>(props.selectedDeviceLink.acd_parent_device_id.toString());
  const [acdChildDeviceId, setAcdChildDeviceIdValue] = useState<string>(props.selectedDeviceLink.acd_child_device_id.toString());
  const [acdSubchildDeviceId, setAcdSubchildDeviceIdValue] = useState<string>(props.selectedDeviceLink.acd_subchild_device_id.toString());
  const [cameraDeviceOptions, setCameraDeviceOptions] = useState<SelectOptionInterface[]>(props.cameraDeviceOptions);
  const [cameraPageSize, handleCameraPageSizeChange] = fetchPageSize('create_links_camera',20);
  const [devicePageSize, handleDevicePageSizeChange] = fetchPageSize('create_links_devices',20);
  const [enabledValue, setEnabledValue] = useState<string>(() => props.selectedDeviceLink.enabled!);
  const [filterLinkedCameras, setFilterLinkedCameras] = useState<boolean>(true);
  const [filterLinkedDevices, setFilterLinkedDevices] = useState<boolean>(true);
  const [idValue, setIdValue] = useState<string>(() => props.selectedDeviceLink.id!);
  const [loadingDevices, setLoadingDevices] = useState<boolean>(false);
  const [loadingCameras , setLoadingCameras] = useState<boolean>(false);
  const [systemIdValue, setSystemIdValue] = useState<string>(props.selectedDeviceLink.system_id);
  const [systemNameValue, setSystemNameValue] = useState<string>(props.selectedDeviceLink.system_name);
  const [saving, setSaving] = useState<boolean>(false);
  const [selectedAcdDevice, setSelectedAcdDevice] = useState<SelectOptionInterface[]>(
    [
      {
        label:acdDeviceName,
        value: `${acdDeviceType}-${acdParentDeviceId}-${acdChildDeviceId}-${acdSubchildDeviceId}`,
      }
    ]
  );
  const [selectedCameras, setSelectedCameras] = useState<{label: string, value: string}[]>(
    [
      {
        label: props.selectedDeviceLink.camera_name,
        value: props.selectedDeviceLink.camera_system_id,
      }
    ]
  );

  const [bundle, isBundleLoading] = useBundle('components.DeviceLinks.Edit');

  const forceAwakensState = useHookState(forceAwakensBaseState);

  const { timeStamp, blobUrl, cameraImage, loading, fetchImage } = FetchCameraImage();
  const { isDoraClientVisible, isNonProd, setIsDoraClientVisible, setDeviceId, DoraClients } = useDoraClients();

  if (idValue !== props.selectedDeviceLink.id) {
    setIdValue(props.selectedDeviceLink.id!);
  }

  const updateDeviceLink = async () => {
    setSaving(true);
    try {
      const response = await API.graphql(graphqlOperation(updateDeviceLinkMutation,
        {
          input:
            {
              id: idValue,
              system_id: systemIdValue,
              acd_device_name: acdDeviceName,
              acd_device_type: acdDeviceType,
              acd_parent_device_id: acdParentDeviceId,
              acd_child_device_id: acdChildDeviceId,
              acd_subchild_device_id: acdSubchildDeviceId,
              camera_name: selectedCameras[0].label,
              camera_system_id: selectedCameras[0].value,
              enabled: enabledValue,
              updated_by: forceAwakensState.username.value
            }
        })) as GraphQLResult<APIt.UpdateDeviceLinkV1Mutation>;
      if (response && response.data && response.data.updateDeviceLinkV1) {
        createUserAction({
          actionName: UserActionNames.UpdateDeviceLink,
          username: forceAwakensState.username.value,
          parameters: JSON.stringify({
            updateData: response.data.updateDeviceLinkV1,
          })
        });
        const updatedDeviceLink = response.data.updateDeviceLinkV1;
        const updatedDeviceLinkV2: DeviceLinkV2 = {
          __typename: 'DeviceLinkV2',
          acd_device_name: updatedDeviceLink.acd_device_name,
          acd_device_type: updatedDeviceLink.acd_device_type,
          acd_parent_device_id: updatedDeviceLink.acd_parent_device_id,
          acd_child_device_id: updatedDeviceLink.acd_child_device_id,
          acd_subchild_device_id: updatedDeviceLink.acd_subchild_device_id,
          created: '',
          created_by: '',
          camera_name: updatedDeviceLink.camera_name,
          camera_system_id: updatedDeviceLink.camera_system_id,
          enabled: updatedDeviceLink.enabled,
          id: updatedDeviceLink.id,
          updated_by: updatedDeviceLink.updated_by,
          updated: updatedDeviceLink.updated,
          system_id: updatedDeviceLink.system_id,
          system_name: updatedDeviceLink.system_name,
        };
        props.saveCallback(updatedDeviceLinkV2);
      }
    } catch (e) {
      setSaving(false);
      createUserAction({
        actionName: UserActionNames.UpdateDeviceLinkError,
        username: forceAwakensState.username.value,
        parameters: JSON.stringify({
          updateError: e,
        })
      });
      console.log(`updateDeviceLink(): exception is ${JSON.stringify(e)}`);
    }
    setSaving(false);
  };

  const cancelBtnHandler = () => {
    props.cancelCallback();
  };

  const saveBtnHandler = () => {
    updateDeviceLink();
  };

  const deviceLinkEnabledFieldOnChangeHandler = (detail: any) => {
    detail.checked ? setEnabledValue('Y') : setEnabledValue('N');
  };

  const deviceLinkSystemFieldOnChangeHandler = (detail: any) => {
    setSystemIdValue(detail.selectedOption.value);
    setSystemNameValue(detail.selectedOption.label);
  };

  const deviceLinkAccessControlDeviceFieldOnChangeHandler = (detail: any) => {
    const selectedDevice = detail.selectedItems[0];
    setAcdDeviceNameValue(selectedDevice['label']);
    setAcdDeviceTypeValue(selectedDevice['value'].split('-')[0]);
    setAcdParentDeviceIdValue(selectedDevice['value'].split('-')[1]);
    setAcdChildDeviceIdValue(selectedDevice['value'].split('-')[2]);
    setAcdSubchildDeviceIdValue(selectedDevice['value'].split('-')[3]);
    setSelectedAcdDevice([{label: selectedDevice['label'], value: selectedDevice['value']}]);
  };

  const getFilterCounterText = (count: number) => `${count} ${count === 1 ? 'match' : 'matches'}`;

  const itemsCountCamera = (): number => {
    if (cameraDeviceOptions) return cameraDeviceOptions.length;
    return 0;
  };

  const itemsCountDevices = (): number => {
    if (acdDeviceOptions) return acdDeviceOptions.length;
    return 0;
  };
  
  const { items: cameraItems, actions: cameraActions, filteredItemsCount: filteredCameraItemsCount, filterProps: camerafilterProps, paginationProps: cameraPaginationProps } = useCollection(
    cameraDeviceOptions,
    {
      filtering: {
        empty: <TableEmptyState title={isBundleLoading ? 'Not Found' : bundle.getMessage('not-found')} />,
        noMatch: <TableNoMatchState onClearFilter={() => cameraActions.setFiltering('')} />
      },
      pagination: { pageSize: cameraPageSize },
      sorting: {},
      selection: { trackBy: "value" }
    }
  );

  const { items: deviceItems, actions: deviceActions, filteredItemsCount: filteredDeviceItemsCount, filterProps: devicefilterProps, paginationProps: devicePaginationProps } = useCollection(
    acdDeviceOptions,
    {
      filtering: {
        empty: <TableEmptyState title={isBundleLoading ? 'Not Found' : bundle.getMessage('not-found')} />,
        noMatch: <TableNoMatchState onClearFilter={() => deviceActions.setFiltering('')} />
      },
      pagination: { pageSize: devicePageSize },
      sorting: {},
      selection: { trackBy: "value" }
    }
  );

  const refreshDevicesBtnClickHandler = async () => {
    setLoadingDevices(true);
    await props.refreshDeviceOptions();
    setLoadingDevices(false);
  };

  const refreshCameraBtnClickHandler = async () => {
    setLoadingCameras(true);
    await props.refreshCameraOptions();
    setLoadingCameras(false);
  };

  useEffect(() => {
    if (filterLinkedCameras) {
      setCameraDeviceOptions(props.cameraDeviceOptions
        .filter(c => !props.deviceLinks.find(dl => dl.camera_system_id === c.value && c.value !== props.selectedDeviceLink.camera_system_id))
        .sort((a, b) => a.label.localeCompare(b.label))
        .sort((a, b) => {
          if (a.value === props.selectedDeviceLink.camera_system_id) return -1;
          if (b.value === props.selectedDeviceLink.camera_system_id) return 1;
          return 0;
        }));
    } else {
      setCameraDeviceOptions(props.cameraDeviceOptions
        .sort((a, b) => a.label.localeCompare(b.label))
        .sort((a, b) => {
          if (a.value === props.selectedDeviceLink.camera_system_id) return -1;
          if (b.value === props.selectedDeviceLink.camera_system_id) return 1;
          return 0;
        })
      )
    }
  }, [filterLinkedCameras]);

  useEffect(() => {
    if (filterLinkedDevices) {
      const unlinkedDeviceOptions = props.acpDeviceOptions
        .filter(c => !props.deviceLinks
          .find(dl => dl.acd_device_name === c.label && c.value !== `${acdDeviceType}-${acdParentDeviceId}-${acdChildDeviceId}-${acdSubchildDeviceId}`))
        .sort((a, b) => a.label.localeCompare(b.label))
        .sort((a, b) => {
          if (a.value === `${acdDeviceType}-${acdParentDeviceId}-${acdChildDeviceId}-${acdSubchildDeviceId}`) return -1;
          if (b.value === `${acdDeviceType}-${acdParentDeviceId}-${acdChildDeviceId}-${acdSubchildDeviceId}`) return 1;
          return 0;
        })
      setACDDeviceOptions(unlinkedDeviceOptions);
    } else {
      setACDDeviceOptions(
        props.acpDeviceOptions
          .sort((a, b) => a.label.localeCompare(b.label))
          .sort((a, b) => {
            if (a.value === `${acdDeviceType}-${acdParentDeviceId}-${acdChildDeviceId}-${acdSubchildDeviceId}`) return -1;
            if (b.value === `${acdDeviceType}-${acdParentDeviceId}-${acdChildDeviceId}-${acdSubchildDeviceId}`) return 1;
            return 0;
          })
      )
    }
  }, [filterLinkedDevices]);

  if (isBundleLoading) return <Spinner/>;

  return (
    <ContentLayout defaultPadding>
      <Form>
        <Box float="right">
          <SpaceBetween size="xs" direction="horizontal">
            <Button
              onClick={cancelBtnHandler}
            >
              {bundle.getMessage('cancel')}
            </Button>
            <Button
              loading={saving}
              onClick={saveBtnHandler}
              variant="primary"
            >
              {bundle.getMessage('save')}
            </Button>
          </SpaceBetween>
        </Box>
        <SpaceBetween size="s" direction="vertical">
          <Grid gridDefinition={[{ colspan: 2 }, { colspan: 10 }]}>
            <FormField label={bundle.getMessage('system')}>
              <Select
                id="DeviceLinkSystemSelectField"
                onChange={({ detail }) => deviceLinkSystemFieldOnChangeHandler(detail)}
                options={props.systemOptions}
                selectedAriaLabel="Selected"
                selectedOption={{ label: systemNameValue, value: systemIdValue }}
              />
            </FormField>
          </Grid>
          <FormField>
            <Checkbox
              onChange={({ detail }) => deviceLinkEnabledFieldOnChangeHandler(detail)}
              checked={enabledValue === 'Y'}
            >
              {bundle.getMessage('enabled')}
            </Checkbox>
          </FormField>
          <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
            <Container>
              <Table
                filter={
                  <TextFilter
                    {...devicefilterProps}
                    filteringAriaLabel="Filter Device Names"
                    filteringPlaceholder={bundle.getMessage('find-device-name')}
                    countText={getFilterCounterText(filteredDeviceItemsCount === undefined ? 0: filteredDeviceItemsCount)}
                  />
                }
                header={
                  <Header
                    counter={`(${itemsCountDevices().toString()})`}
                    actions={<Button onClick={refreshDevicesBtnClickHandler} iconName="refresh"/>}
                  >
                    {bundle.getMessage('acd-header')}
                  </Header>
                }
                onSelectionChange={({ detail }) =>
                  deviceLinkAccessControlDeviceFieldOnChangeHandler(detail)
                }
                selectedItems={selectedAcdDevice}
                columnDefinitions={[
                  {
                    id: 'Access Device Names',
                    header:
                      <div key='device-name-header' style={{display:'flex', alignItems: 'center'}} >
                        <SpaceBetween size='xs' direction='horizontal'>
                          <div key='access-control-device-message'>
                            {bundle.getMessage('access-control-device')}
                          </div>
                          <Toggle
                            checked={filterLinkedDevices}
                            onChange={({ detail }) => setFilterLinkedDevices(detail.checked)}
                          >
                            <div key='unlinked-devices-only-message'>
                              {bundle.getMessage('unlinked-devices-only')}
                            </div>
                          </Toggle>
                        </SpaceBetween>
                      </div>,
                    cell: item => (
                      <div key={item.value}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          {item.label}
                          {isNonProd
                          &&
                          <Popover
                            triggerType="custom"
                            size='large'
                            dismissButton={false}
                            fixedWidth={true}
                            renderWithPortal={true}
                            content={
                              <div>
                                {isDoraClientVisible && <DoraClients />}
                              </div>
                            }
                          >
                            <IconContext.Provider value={{ style: { verticalAlign: 'middle', cursor: 'pointer', marginLeft: '8px', width: '16px', height: '16px' } }}>
                            <SiGooglemaps onClick={() => {
                              const itemValue = item.value; // eg: card_reader-32904-3-0
                              const deviceId = itemValue.split('-').slice(1).join('_'); // eg: 32904_3_0
                              setDeviceId(deviceId);
                              setIsDoraClientVisible(!isDoraClientVisible);
                            }}
                          />
                            </IconContext.Provider>
                          </Popover>
                          }
                        </div>
                      </div>
                    ),
                    sortingField: "value",
                  }
                ]}
                items={deviceItems}
                selectionType="single"
                trackBy="label"
                loading={loadingDevices}
                loadingText={bundle.getMessage('loading-device-text')}
                pagination={
                  <Pagination
                    {...devicePaginationProps}
                    ariaLabels={PaginationLabels}            
                  />
                }
                preferences={
                  <CollectionPreferences
                    onConfirm={({ detail }) => handleDevicePageSizeChange(detail.pageSize || devicePageSize)}
                    title={"User Preferences"}
                    confirmLabel={bundle.getMessage('confirm')}
                    cancelLabel={bundle.getMessage('cancel')}
                    preferences={{
                      pageSize: devicePageSize,
                    }}
                    pageSizePreference={{
                      title: bundle.getMessage('select-page-size'),
                      options: [
                        { value: 25, label: bundle.formatMessage('number-of-devices', { deviceCount: 25, type: bundle.getMessage('device') }) },
                        { value: 50, label: bundle.formatMessage('number-of-devices', { deviceCount: 50, type: bundle.getMessage('device') }) },
                        { value: 100, label: bundle.formatMessage('number-of-devices', { deviceCount: 100, type: bundle.getMessage('device') }) },
                        { value: 150, label: bundle.formatMessage('number-of-devices', { deviceCount: 150, type: bundle.getMessage('device') }) },
                        { value: 250, label: bundle.formatMessage('number-of-devices', { deviceCount: 250, type: bundle.getMessage('device') }) },
                        { value: 500, label: bundle.formatMessage('number-of-devices', { deviceCount: 500, type: bundle.getMessage('device') }) }
                      ],
                    }}
                  />
                }
              />
            </Container>
            <Container>
              <Table
                filter={
                  <TextFilter
                    {...camerafilterProps}
                    filteringAriaLabel="Filter Camera Names"
                    filteringPlaceholder={bundle.getMessage('find-camera-name')}
                    countText={getFilterCounterText(filteredCameraItemsCount === undefined ? 0: filteredCameraItemsCount)}
                  />
                }
                header={
                  <Header
                    counter={`(${itemsCountCamera().toString()})`}
                    actions={<Button onClick={refreshCameraBtnClickHandler} iconName="refresh"/>}
                  >
                    Cameras
                  </Header>
                }
                selectionType="single"
                columnDefinitions={[
                  {
                    id: 'cameraName',
                    header:
                      <div key='cameraName-header' style={{display:'flex', alignItems: 'center'}} >
                        <SpaceBetween size="xs" direction="horizontal">
                          <div key='camera-message'>
                            {bundle.getMessage('camera')}
                          </div>
                          <Toggle
                            checked={filterLinkedCameras}
                            onChange={({ detail }) => setFilterLinkedCameras(detail.checked)}
                          >
                            <div key='unlinked-cameras-only-message'>
                              {bundle.getMessage('unlinked-cameras-only')}
                            </div>
                          </Toggle>
                        </SpaceBetween>
                      </div>,
                    cell: item => (
                      <div key={item.value}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          {item.label}
                          <Popover
                            triggerType="custom"
                            dismissButton={false}
                            size= "large"
                            content={
                              <Box>
                                {loading ? (
                                  <Spinner/>
                                ) : (
                                  cameraImage && (
                                    <>
                                      <img
                                        src={ cameraImage }
                                        style={{ width: "100%", height: "100%", objectFit: "contain" }}
                                      />
                                      {blobUrl && (
                                        <>
                                          <a href={blobUrl} target="_blank" rel="noopener noreferrer">{bundle.getMessage('image-full-size')}</a>
                                          <div>{timeStamp}</div>
                                        </>
                                      )}
                                    </>
                                  )
                                )}
                              </Box>
                            }
                          >
                            <IconContext.Provider value={{ style: { verticalAlign: 'middle', cursor: 'pointer', marginLeft: '8px', width: '16px', height: '16px' } }}>
                              <IoVideocam onClick={() => fetchImage(item.value)} />
                            </IconContext.Provider>
                          </Popover>
                          {isNonProd && <Popover
                            triggerType="custom"
                            dismissButton={false}
                            size='large'
                            content={
                              <>
                                {isDoraClientVisible && <DoraClients />}
                              </>
                            }
                          >
                            <IconContext.Provider value={{ style: { verticalAlign: 'middle', cursor: 'pointer', marginLeft: '8px', width: '16px', height: '16px' } }}>
                              <SiGooglemaps onClick={() => {
                                setDeviceId(item.value);
                                setIsDoraClientVisible(!isDoraClientVisible);
                              }}
                          />
                            </IconContext.Provider>
                          </Popover>
                          }
                        </div>
                      </div>
                    ),
                  },
                ]}
                onSelectionChange={({ detail }) => setSelectedCameras(detail.selectedItems)}
                items={cameraItems}
                loading={loadingCameras}
                loadingText={bundle.getMessage('loading-camera-text')}
                selectedItems={selectedCameras}
                trackBy="value"
                pagination={
                  <Pagination
                    {...cameraPaginationProps}
                    ariaLabels={PaginationLabels}            
                  />
                }
                preferences={
                  <CollectionPreferences
                    onConfirm={({ detail }) => handleCameraPageSizeChange(detail.pageSize || cameraPageSize)}
                    title={"User Preferences"}
                    confirmLabel={"Confirm"}
                    cancelLabel={"Cancel"}
                    preferences={{
                      pageSize: cameraPageSize,
                    }}
                    pageSizePreference={{
                      title: "Select Page Size",
                      options: [
                        { value: 25, label: bundle.formatMessage('number-of-devices', { deviceCount: 25, type: bundle.getMessage('cameras') }) },
                        { value: 50, label: bundle.formatMessage('number-of-devices', { deviceCount: 50, type: bundle.getMessage('cameras') }) },
                        { value: 100, label: bundle.formatMessage('number-of-devices', { deviceCount: 100, type: bundle.getMessage('cameras') }) },
                        { value: 150, label: bundle.formatMessage('number-of-devices', { deviceCount: 150, type: bundle.getMessage('cameras') }) },
                        { value: 250, label: bundle.formatMessage('number-of-devices', { deviceCount: 250, type: bundle.getMessage('cameras') }) },
                        { value: 500, label: bundle.formatMessage('number-of-devices', { deviceCount: 500, type: bundle.getMessage('cameras') }) }
                      ],
                    }}
                  />
                }
              />
            </Container>
          </Grid>
        </SpaceBetween>
      </Form>
    </ContentLayout>
  );
}
