import React, { useState } from 'react';
import { useState as useHookState } from '@hookstate/core';
import {
  Button,
  Container,
  Form,
  FormField,
  Select,
  SpaceBetween,
  Spinner
} from '@amzn/awsui-components-react';
import API, { GraphQLResult, graphqlOperation } from '@aws-amplify/api';
import { createDeviceLinkEventV1 as createDeviceLinkEventMutation } from "../../graphql/mutations";
import { CreatedDeviceLinkEventInterface } from './ChildTablePanel';
import { CancelCreateDeviceLinkEventInterface } from './ChildTablePanel';
import { CreateDeviceLinkEventV1Mutation, DeviceLinkEvent } from 'src/API';
import { createUserAction } from 'src/utils/UserActionsUtils';
import { SelectOptionInterface } from './MainContents';
import { forceAwakensBaseState } from 'src/stores/app';
import { useBundle } from '@amzn/react-arb-tools';
import { UserActionNames } from 'src/constants/Constants';

export interface DeviceLinkValueCreatePanelPropsInterface {
  deviceLinkId: string;
  deviceLinkEvents: DeviceLinkEvent[];
  eventOptions: SelectOptionInterface[];
  saveCallback: CreatedDeviceLinkEventInterface;
  cancelCreateCallback: CancelCreateDeviceLinkEventInterface;
}

export const DeviceLinkEventCreatePanel = (props: DeviceLinkValueCreatePanelPropsInterface) => {

  const [eventIdValue, setEventIdValue] = useState<string>();
  const [eventNameValue, setEventNameValue] = useState<string>();

  const [bundle, isBundleLoading] = useBundle('components.DeviceLinks.ChildCreate');

  const forceAwakensState = useHookState(forceAwakensBaseState);

  const createDeviceLinkEvent = async () => {
    try {
      const response = await API.graphql(graphqlOperation(createDeviceLinkEventMutation,
        {
          input:
            {
              device_link_id: props.deviceLinkId,
              event_id: eventIdValue,
              created_by: forceAwakensState.username.value
            }
        })) as GraphQLResult<CreateDeviceLinkEventV1Mutation>;
      if (response && response.data && response.data.createDeviceLinkEventV1) {
        createUserAction(
          {
            actionName: UserActionNames.CreateDeviceLinkEvent,
            username: forceAwakensState.username.value,
            parameters: JSON.stringify(
            {
              device_link_id: props.deviceLinkId,
              event_name: eventNameValue,
            })
          });
        const createdDeviceLinkEvent = response.data.createDeviceLinkEventV1;
        props.saveCallback(createdDeviceLinkEvent);
      }
    } catch (e) {
      console.log(`createDeviceLinkEvent(): exception is ${JSON.stringify(e)}`);
      createUserAction(
        {
          actionName: UserActionNames.CreateDeviceLinkEvError,
          username: forceAwakensState.username.value,
          parameters: JSON.stringify(
          {
            device_link_id: props.deviceLinkId,
            event_name: eventNameValue,
            createDeviceLinkEventError: e,
          })
        });
    }
  }

  const cancelBtnHandler = () => {
    props.cancelCreateCallback();
  }

  const saveBtnHandler = () => {
    if (eventIdValue) createDeviceLinkEvent();
  }

  const deviceLinkEventEventFieldOnChangeHandler = (detail: any) => {
    setEventIdValue(detail.selectedOption.value);
    setEventNameValue(detail.selectedOption.label);
  };

  if (isBundleLoading) return <Spinner/>;

  return (
    <div>
      <Container>
        <Form>
          <SpaceBetween size="xs" direction="vertical">
            <FormField label={bundle.getMessage('event')}>
              <Select
                id="DeviceLinkEventEventInputField"
                filteringType='auto'
                onChange={({ detail }) => deviceLinkEventEventFieldOnChangeHandler(detail)}
                options={props.eventOptions.filter(eo => props.deviceLinkEvents.findIndex(e => e.event_id === eo.value) === -1)}
                selectedAriaLabel="Selected"
                selectedOption={{ label: eventNameValue, value: eventIdValue }}
              />
            </FormField>
            <SpaceBetween size="xs" direction="horizontal">
              <Button onClick={cancelBtnHandler}>{bundle.getMessage('cancel')}</Button>
              <Button onClick={saveBtnHandler} variant="primary">{bundle.getMessage('save')}</Button>
            </SpaceBetween>
          </SpaceBetween>
        </Form>
      </Container>
    </div>
  );
}
