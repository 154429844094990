import React, { useState, useEffect } from 'react';
import { useState as useHookState } from '@hookstate/core';
import { Box, Button, Flashbar, Modal, Pagination, SpaceBetween, Spinner, Table, TextFilter } from '@amzn/awsui-components-react';
import { useCollection } from '@amzn/awsui-collection-hooks';
import Header from '@amzn/awsui-components-react/polaris/header';
import { ColumnDefinitions, PaginationLabels, TableEmptyState, TableNoMatchState } from './child-table-config';
import * as APIt from "../../API";
import API, { graphqlOperation } from '@aws-amplify/api';
import { deleteDeviceLinkEventV1 as deleteDeviceLinkEventMutation } from "../../graphql/mutations";
import { SelectOptionInterface } from "./MainContents";
import { DeviceLinkEventEditPanel } from './ChildEdit';
import { DeviceLinkEventCreatePanel } from './ChildCreate';
import { forceAwakensBaseState } from '../../stores/app';
import { useBundle } from '@amzn/react-arb-tools';
import { createUserAction } from 'src/utils/UserActionsUtils';
import { UserActionNames } from 'src/constants/Constants';

export interface CancelCreateDeviceLinkEventInterface {
  (): void;
}
export interface CreatedDeviceLinkEventInterface {
  (createdDeviceLinkEvent: APIt.DeviceLinkEvent): void;
}
export interface CancelUpdateDeviceLinkEventInterface {
  (): void;
}
export interface UpdatedDeviceLinkEventInterface {
  (updatedDeviceLinkEvent: APIt.DeviceLinkEvent): void;
}
export interface DeviceLinkEventsTablePanelPropsInterface {
  addDeviceLinkEvent: Function;
  deleteDeviceLinkEvent: Function;
  editDeviceLinkEvent: Function;
  deviceLinkId: string;
  deviceLinkEvents: APIt.DeviceLinkEvent[];
  eventOptions: SelectOptionInterface[];
  isTableLoading: boolean;
  refreshCallback: Function;
}

export default function DeviceLinkEventsTablePanel(props: DeviceLinkEventsTablePanelPropsInterface ) {

  const [allItems, setAllItems] = useState<APIt.DeviceLinkEvent[]>(props.deviceLinkEvents);
  const [deleteVisible, setDeleteVisible] = useState<boolean>(false);
  const [deviceLinkEventCreateSaved, setDeviceLinkEventCreateSaved] = useState<boolean>(false);
  const [deviceLinkEventDeleteSaved, setDeviceLinkEventDeleteSaved] = useState<boolean>(false);
  const [deviceLinkEventEditSaved, setDeviceLinkEventEditSaved] = useState<boolean>(false);
  const [hideDeviceLinkEventsTable, setHideDeviceLinkEventsTable] = useState<boolean>(false);
  const [selectedDeviceLinkEvent, setSelectedDeviceLinkEvent] = useState<APIt.DeviceLinkEvent>();
  const [selectedItems, setSelectedItems] = useState<APIt.DeviceLinkEvent[]>();
  const [showCreateDeviceLinkEventPanel, setShowCreateDeviceLinkEventPanel] = useState<boolean>(false);
  const [showEditDeviceLinkEventPanel, setShowEditDeviceLinkEventPanel] = useState<boolean>(false);

  const [bundle, isBundleLoading] = useBundle('components.DeviceLinks.ChildTablePanel');

  const forceAwakensState = useHookState(forceAwakensBaseState);

  const pageSize = 10;

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    allItems,
    {
      filtering: {
        empty: <TableEmptyState title={isBundleLoading ? 'Not Found' : bundle.getMessage('not-found')} />,
        noMatch: <TableNoMatchState onClearFilter={() => actions.setFiltering('')} />
      },
      pagination: { pageSize: pageSize },
      sorting: {},
      selection: { trackBy: "id" }
    }
  );

  if (!items) return <><p>No Device Link Events because items does not exist</p></>;

  const getFilterCounterText = (count: number) => `${count} ${count === 1 ? 'match' : 'matches'}`;

  const cancelCreate: CancelCreateDeviceLinkEventInterface = () => {
    setHideDeviceLinkEventsTable(false);
    setShowCreateDeviceLinkEventPanel(false);
  }

  const cancelUpdate = () => {
    setHideDeviceLinkEventsTable(false);
    setShowEditDeviceLinkEventPanel(false);
  }

  const createBtnClickHandler = () => {
    setHideDeviceLinkEventsTable(true);
    setShowCreateDeviceLinkEventPanel(true);
  };

  const createdDeviceLinkEvent: CreatedDeviceLinkEventInterface = (createdDeviceLinkEvent: APIt.DeviceLinkEvent) => {
    if (!allItems) return;
    const newAllItems = allItems;
    newAllItems.push(createdDeviceLinkEvent);
    setAllItems(newAllItems);
    setHideDeviceLinkEventsTable(false);
    setDeviceLinkEventCreateSaved(true);
    setShowCreateDeviceLinkEventPanel(false);
    props.addDeviceLinkEvent(createdDeviceLinkEvent);
  }

  const resetDeviceLinkEventCreatedSaved = () => {
    setDeviceLinkEventCreateSaved(false);
  };

  const FlashMessageCreateSaved = () => (
    <Flashbar
      items = {
        [
          {
            type: 'info',
            dismissible: false,
            content: bundle.getMessage('created'),
            buttonText: 'OK',
            onButtonClick: resetDeviceLinkEventCreatedSaved
          },
        ]
      }
    />
  );

  const editBtnClickHandler = () => {
    setHideDeviceLinkEventsTable(true);
    setShowEditDeviceLinkEventPanel(true);
  };

  const updatedDeviceLinkEvent: UpdatedDeviceLinkEventInterface = (updatedDeviceLinkEvent: APIt.DeviceLinkEvent) => {
    if (!allItems) return;
    const newAllItems = allItems.filter( (item) => item.id !== updatedDeviceLinkEvent.id );
    newAllItems.push(updatedDeviceLinkEvent);
    setAllItems(newAllItems);
    setShowEditDeviceLinkEventPanel(false);
    setHideDeviceLinkEventsTable(false);
    setDeviceLinkEventEditSaved(true);
    setSelectedDeviceLinkEvent(updatedDeviceLinkEvent);
    props.editDeviceLinkEvent(updatedDeviceLinkEvent);
  };

  const resetDeviceLinkEventEditSaved = () => {
    setHideDeviceLinkEventsTable(false);
    setDeviceLinkEventEditSaved(false);
  };

  const FlashMessageEditSaved = () => (
    <Flashbar
      items = {
        [
          {
            type: 'info',
            dismissible: false,
            content: bundle.getMessage('saved'),
            buttonText: 'OK',
            onButtonClick: resetDeviceLinkEventEditSaved
          },
        ]
      }
    />
  );

  const deleteBtnClickHandler = () => {
    if (selectedDeviceLinkEvent && selectedDeviceLinkEvent.id) setDeleteVisible(true);
  };

  const deleteNoBtnClickHandler = () => {
    setDeleteVisible(false);
  };

  const deleteYesBtnClickHandler = () => {
    if (selectedDeviceLinkEvent && selectedDeviceLinkEvent.id) {
      deleteDeviceLinkEvent(selectedDeviceLinkEvent.id);
      props.deleteDeviceLinkEvent(selectedDeviceLinkEvent);
    }
  };

  const deleteDeviceLinkEvent = async (id: string) => {
    try {
      await API.graphql(graphqlOperation(deleteDeviceLinkEventMutation,
        {
          input:
            {
              id: id,
              updated_by: forceAwakensState.username.value
            }
        }));
        createUserAction({
          actionName: UserActionNames.DeleteDeviceLinkEvent,
          username: forceAwakensState.username.value,
          parameters: JSON.stringify({
            device_link: selectedDeviceLinkEvent,
          })
        });
    } catch (error) {
      console.log(`deleteDeviceLinkEvent(): error is ${JSON.stringify(error)}`);
      createUserAction({
        actionName: UserActionNames.DeleteDeviceLinkEvError,
        username: forceAwakensState.username.value,
        parameters: JSON.stringify({
          delete_error: error,
        })
      });
    }
    setSelectedDeviceLinkEvent(undefined);
    const newAllItems = allItems!.filter( (item) => item.id !== id );
    setAllItems(newAllItems);
    setDeleteVisible(false);
    setDeviceLinkEventDeleteSaved(true);
  }

  const resetDeviceLinkEventDeleteSaved = () => {
    setDeviceLinkEventDeleteSaved(false);
  };

  const FlashMessageDeleteSaved = () => (
    <Flashbar
      items = {
        [
          {
            type: 'info',
            dismissible: false,
            content: bundle.getMessage('deleted'),
            buttonText: 'OK',
            onButtonClick: resetDeviceLinkEventDeleteSaved
          },
        ]
      }
    />
  );

  const refreshBtnClickHandler = () => {
    props.refreshCallback();
  };

  const itemsCount = (): number => {
    if (allItems) return allItems.length;
    return 0;
  };

  useEffect(() => {
    setAllItems(props.deviceLinkEvents);
  }, [props.deviceLinkEvents]);

  useEffect(() => {
    if (selectedItems && selectedItems.length > 0) {
      setSelectedDeviceLinkEvent(selectedItems[0]);
    }
  }, [selectedItems]);

  if (isBundleLoading) return <Spinner/>;

  return(
    <>
    {deviceLinkEventEditSaved && <FlashMessageEditSaved/>}
    {deviceLinkEventCreateSaved && <FlashMessageCreateSaved/>}
    {deviceLinkEventDeleteSaved && <FlashMessageDeleteSaved/>}
    <div id="deviceLinkEventsTableDiv" hidden={hideDeviceLinkEventsTable}>
      <Table
        {...collectionProps}
        columnDefinitions={ColumnDefinitions}
        filter={
          <TextFilter
            {...filterProps}
            filteringAriaLabel="Filter Device Link Events"
            filteringPlaceholder="Find Device Link Events"
            countText={getFilterCounterText(filteredItemsCount === undefined ? 0: filteredItemsCount)}
          />
        }
        header={
          <Header
            counter={`(${itemsCount().toString()})`}
            actions={
              <>
              <SpaceBetween size="xs" direction="horizontal">
                <Button onClick={refreshBtnClickHandler} iconName="refresh"/>
                <Button onClick={editBtnClickHandler} disabled={selectedDeviceLinkEvent === undefined}>{bundle.getMessage('edit')}</Button>
                <Button onClick={deleteBtnClickHandler} disabled={selectedDeviceLinkEvent === undefined}>{bundle.getMessage('delete')}</Button>
                <Button variant="primary" onClick={createBtnClickHandler}>{bundle.getMessage('create')}</Button>
              </SpaceBetween>
              </>
            }
          >
            Events
          </Header>
        }
        items={items}
        loading={props.isTableLoading}
        loadingText={`${bundle.getMessage('loading')}`}
        onSelectionChange={({ detail }) => setSelectedItems(detail.selectedItems) }
        pagination={
          <Pagination
            {...paginationProps}
            ariaLabels={PaginationLabels}
          />
        }
        resizableColumns={true}
        selectedItems={selectedItems}
        selectionType="single"
        stickyHeader={false}
        trackBy="id"
      />
    </div>
    <Modal
      onDismiss={() => setDeleteVisible(false)}
      visible={deleteVisible}
      closeAriaLabel="Close"
      size="medium"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={deleteNoBtnClickHandler}>{bundle.getMessage('no')}</Button>
            <Button variant="primary" onClick={deleteYesBtnClickHandler}>{bundle.getMessage('yes')}</Button>
          </SpaceBetween>
        </Box>
      }
      header={bundle.getMessage('delete')}
    >
      {bundle.getMessage('confirm-delete')}
    </Modal>
    {
      selectedDeviceLinkEvent
      && showEditDeviceLinkEventPanel
      &&
      <DeviceLinkEventEditPanel
        cancelCallback={cancelUpdate}
        deviceLinkEvents={[...items]}
        eventOptions={props.eventOptions}
        saveCallback={updatedDeviceLinkEvent}
        selectedDeviceLinkEvent={selectedDeviceLinkEvent}
      />
    }
    {
      showCreateDeviceLinkEventPanel
      &&
      <DeviceLinkEventCreatePanel
        deviceLinkId={props.deviceLinkId}
        deviceLinkEvents={[...items]}
        eventOptions={props.eventOptions}
        saveCallback={createdDeviceLinkEvent}
        cancelCreateCallback={cancelCreate}
      />
    }
    </>
  );
}
