import {
  ConditionOperandV1,
  CreateDeviceActionConditionInputV1,
  DeleteDeviceActionConditionInputV1,
  DeviceActionConditionV1,
  UpdateDeviceActionConditionInputV1,
} from 'src/API';
import {
  Alert,
  Box,
  Button,
  Header,
  Input,
  Modal,
  Select,
  SpaceBetween,
  Spinner,
  Table,
  TableProps,
} from '@amzn/awsui-components-react';
import {
  DefaultPageSize,
  TableEmptyState,
  TableNoMatchState,
} from './DeviceActionConditionsTableConfig';
import {
  createDeviceActionCondition,
  deleteDeviceActionCondition,
  listConditionOperands,
  listDeviceActionConditionsForGroup,
  updateDeviceActionCondition,
} from './utils';
import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import { ForceAwakensStateInterface } from 'src/stores/app';
import { State } from '@hookstate/core';
import { useBundle } from '@amzn/react-arb-tools';
import { useCollection } from '@amzn/awsui-collection-hooks';

interface IDeviceActionConditionsTablePanel {
  forceAwakensState: State<ForceAwakensStateInterface>;
  groupId: string;
}

export default function DeviceActionConditionsTablePanel(props: IDeviceActionConditionsTablePanel) {
  console.log(`DeviceActionConditionsTablePanel() props.groupId is ${JSON.stringify(props.groupId)}`);

  const queryClient = useQueryClient();

  const [conditionOperands, setConditionOperands] = useState<ConditionOperandV1[]>([]);
  const [deviceActionConditions, setDeviceActionConditions] = useState<DeviceActionConditionV1[]>([]);
  const [error, setError] = useState<string | undefined>();
  const [selectedDeviceActionConditions, setSelectedDeviceActionConditions] = useState<DeviceActionConditionV1[]>([]);
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);

  const conditionOperators = ['=', '!=', '>', '<', '>=', '<='];

  const [bundle, isBundleLoading] = useBundle('components.DeviceActions.DeviceActionConditionsTablePanel');

  const { items, actions: collectionActions, paginationProps } = useCollection(
    deviceActionConditions,
    {
      filtering: {
        empty: <TableEmptyState title={isBundleLoading ? 'No Conditions Found' : bundle.getMessage('no-conditions-found')} />,
        noMatch: <TableNoMatchState onClearFilter={() => collectionActions.setFiltering('')} />,
      },
      pagination: { pageSize: DefaultPageSize.pageSize },
      sorting: {},
      selection: { trackBy: 'id' }
    }
  );

  const conditionOperandsQuery = useQuery<ConditionOperandV1[]>(
    ['conditionOperands'],
    async () => await listConditionOperands(),
    {
      onSettled: (data, error) => {
        setError(undefined);
        if (error) {
          setError(typeof error === 'object' ? JSON.stringify(error) : error as string);
          return;
        }
        setConditionOperands([]);
        if (data) setConditionOperands(data);
      },
      refetchOnWindowFocus: false,
    }
  );

  const deviceActionConditionsQuery = useQuery<DeviceActionConditionV1[]>(
    ['deviceActionConditions'],
    async () => await listDeviceActionConditionsForGroup(props.groupId),
    {
      enabled: !!props.groupId,
      onSettled: (data, error) => {
        setError(undefined);
        if (error) {
          setError(typeof error === 'object' ? JSON.stringify(error) : error as string);
          return;
        }
        setDeviceActionConditions([]);
        if (data) setDeviceActionConditions(data);
      },
      refetchOnWindowFocus: false,
    }
  );

  const saveDeviceActionConditionMutation = useMutation<DeviceActionConditionV1, Error, DeviceActionConditionV1>(
    async (input: DeviceActionConditionV1): Promise<DeviceActionConditionV1> => {
      const currentDeviceActionCondition = deviceActionConditions.find(dap => dap.id !== '' && dap.id !== null && dap.id === input.id);
      if (currentDeviceActionCondition) {
        const updateDeviceActionConditionInput: UpdateDeviceActionConditionInputV1 = {
          group_id: input.group_id,
          id: input.id,
          operator: input.operator,
          operand_id: input.operand_id,
          value: input.value,
          updated_by: props.forceAwakensState.username.value!,
        };
        return await updateDeviceActionCondition(updateDeviceActionConditionInput);
      }
      const createDeviceActionConditionInput: CreateDeviceActionConditionInputV1 = {
        created_by: props.forceAwakensState.username.value!,
        group_id: input.group_id,
        operand_id: input.operand_id,
        value: input.value,
        operator: input.operator,
      };
      return await createDeviceActionCondition(createDeviceActionConditionInput);
    },
    {
      onSettled: (data, error) => {
        setError(undefined);
        if (error) {
          setError(typeof error === 'object' ? JSON.stringify(error) : error as string);
          return;
        }
        const savedDeviceActionConditionIndex = deviceActionConditions.findIndex(a => a.id === data?.id);
        const newDeviceActionConditions = [...deviceActionConditions.filter(a => a.id !== data?.id && a.id !== '')];
        if (data) {
          const savedDeviceActionCondition = {...data};
          savedDeviceActionCondition.operand_name = conditionOperands.find(co => co.id === savedDeviceActionCondition.operand_id)?.name;
          if (savedDeviceActionConditionIndex !== -1) newDeviceActionConditions.splice(savedDeviceActionConditionIndex, 0, savedDeviceActionCondition);
          if (savedDeviceActionConditionIndex === -1) newDeviceActionConditions.push(savedDeviceActionCondition);
        }
        if (deviceActionConditions.findIndex(a => a.id === data?.id) === -1) collectionActions.setCurrentPage(paginationProps.pagesCount);
        setDeviceActionConditions([...newDeviceActionConditions]);
      },
    },
  );

  const deleteDeviceActionConditionMutation = useMutation<DeviceActionConditionV1 | undefined, Error, DeleteDeviceActionConditionInputV1>(
    async (input: DeleteDeviceActionConditionInputV1) => {
      const currentDeviceActionCondition = deviceActionConditions.find(dac => dac.id === input.id);
      if (currentDeviceActionCondition && currentDeviceActionCondition.id !== '') return(await deleteDeviceActionCondition(input));
    },
    {
      onSettled: (data, error) => {
        setError(undefined);
        if (error) {
          setError(typeof error === 'object' ? JSON.stringify(error) : error as string);
          setShowConfirmDelete(false);
          return;
        }
        const newDeviceActionConditions = [...deviceActionConditions.filter(dac => dac.id !== data?.id)];
        setSelectedDeviceActionConditions(selectedDeviceActionConditions.filter(dac => dac.id !== data?.id));
        setDeviceActionConditions([...newDeviceActionConditions]);
      },
    },
  );

  const submitEdit = async (
    currentItem: DeviceActionConditionV1,
    column: TableProps.ColumnDefinition<DeviceActionConditionV1>,
    value: any) =>
  {
    const newDeviceActionConditions = [...deviceActionConditions];
    const index = newDeviceActionConditions.findIndex((v) => v.group_id === currentItem.group_id && v.operator === currentItem.operator);
    if (index !== -1) {
      const newDeviceActionCondition: DeviceActionConditionV1 = {...newDeviceActionConditions[index]};
      switch (column.id) {
        case 'operand':
          newDeviceActionCondition.operand_id = value.value;
          newDeviceActionCondition.operand_name = value.label;
          break;

        case 'operator':
          newDeviceActionCondition.operator = value.value;
          break;

        case 'value':
          newDeviceActionCondition.value = value;
          break;

        default:
          console.error(`submitEdit() column.id is ${column.id} which is not supported`);
      }
      newDeviceActionConditions[index] = newDeviceActionCondition;
      setDeviceActionConditions(newDeviceActionConditions);
      if (newDeviceActionCondition.operand_id && newDeviceActionCondition.operator && newDeviceActionCondition.value) {
        await saveDeviceActionConditionMutation.mutateAsync(newDeviceActionCondition);
      }
    }
  };

  const selectConditionRef = useRef<any>(null);

  const addDeviceActionCondition = () => {
    const newDeviceActionConditions = [...deviceActionConditions];
    const newDeviceActionCondition: DeviceActionConditionV1 = {
      __typename: 'DeviceActionConditionV1',
      id: '',
      group_id: props.groupId,
      operator: '',
      operand_id: '',
      operand_name: '',
      value: '',
      created: '',
      created_by: props.forceAwakensState.username.value!,
      updated: '',
      updated_by: props.forceAwakensState.username.value!,
    };
    newDeviceActionConditions.push(newDeviceActionCondition);
    setDeviceActionConditions(newDeviceActionConditions);
    collectionActions.setCurrentPage(paginationProps.pagesCount);
    selectConditionRef.current?.focus();
  };

  const deleteDeviceActionConditions = async () => {
    if (selectedDeviceActionConditions[0].id === '') {
      setDeviceActionConditions(deviceActionConditions.filter(dac => dac.id !== ''));
      setShowConfirmDelete(false);
      setError(undefined);
      return;
    }
    for (const selectedDeviceActionCondition of selectedDeviceActionConditions.filter(dap => dap.id !== '')) {
      const deleteDeviceActionConditionInput: DeleteDeviceActionConditionInputV1 = {
        id: selectedDeviceActionCondition.id,
        updated_by: props.forceAwakensState.username.value!,
      };
      await deleteDeviceActionConditionMutation.mutateAsync(deleteDeviceActionConditionInput);
    }
    setShowConfirmDelete(false);
  };

  const refresh = () => {
    setSelectedDeviceActionConditions([]);
    queryClient.refetchQueries(['conditionOperands']);
    queryClient.refetchQueries(['deviceActionConditions']);
  };

  useEffect(() => {
    setSelectedDeviceActionConditions([]);
    queryClient.refetchQueries(['deviceActionConditions']);
  }, [props.groupId]);

  if (isBundleLoading) return <Spinner/>;

  const ColumnDefinitions: TableProps.ColumnDefinition<DeviceActionConditionV1>[] = [
    {
      cell: (item: DeviceActionConditionV1) => item.operand_name,
      editConfig: {
        ariaLabel: 'Operand',
        editIconAriaLabel: 'editable',
        errorIconAriaLabel: 'Operand Error',
        editingCell: (item, { currentValue, setValue }) => {
          return(
            <Select
              autoFocus
              expandToViewport
              onChange={({ detail }) => {
                setValue(({ label: detail.selectedOption.label, value: detail.selectedOption.value }));
              }}
              options={[...conditionOperands].map(co => ({label: co.name, value: co.id}))}
              ref={selectConditionRef}
              selectedOption={currentValue ?? {
                label: item.operand_name,
                value: item.operand_id,
              }}
            />);
        },
      },
      header: bundle.getMessage('operand'),
      id: 'operand',
    },
    {
      cell: (item: DeviceActionConditionV1) => item.operator,
      editConfig: {
        ariaLabel: 'Condition',
        editIconAriaLabel: 'editable',
        errorIconAriaLabel: 'Condition Error',
        editingCell: (item, { currentValue, setValue }) => {
          return(
            <Select
              autoFocus
              expandToViewport
              onChange={({ detail }) => {
                setValue(({ label: detail.selectedOption.label, value: detail.selectedOption.value }));
              }}
              options={[...conditionOperators].map(cgo => ({label: cgo, value: cgo}))}
              ref={selectConditionRef}
              selectedOption={currentValue ?? {
                label: item.operator,
                value: item.operator,
              }}
            />);
        },
      },
      header: bundle.getMessage('operator'),
      id: 'operator',
    },
    {
      cell: (item: DeviceActionConditionV1) => item.value,
      editConfig: {
        ariaLabel: 'Value',
        editIconAriaLabel: 'editable',
        errorIconAriaLabel: 'Value Error',
        editingCell: (item, { currentValue, setValue }) => {
          return(
            <Input
              onChange={({ detail }) => {
                setValue(detail.value);
              }}
              ref={selectConditionRef}
              value={currentValue ?? item.value}
            />);
        },
      },
      header: bundle.getMessage('value'),
      id: 'value',
    },
  ];

  return(
    <div id='DeviceActionConditionTablePanel'>
      {showConfirmDelete
      &&
      <Modal
        closeAriaLabel='Close'
        onDismiss={() => setShowConfirmDelete(false)}
        visible={showConfirmDelete}
        size='medium'
        footer={
          <Box float='right'>
            <SpaceBetween direction='horizontal' size='xs'>
              <Button
                onClick={() => setShowConfirmDelete(false)}
                variant='link'
              >
                {bundle.getMessage('no')}
              </Button>
              <Button
                disabled={deleteDeviceActionConditionMutation.isLoading}
                onClick={() => deleteDeviceActionConditions()}
                variant='primary'
              >
                {bundle.getMessage('yes')}
              </Button>
            </SpaceBetween>
          </Box>
        }
        header={`${bundle.getMessage('delete')} (${selectedDeviceActionConditions.length})`}
      >
        {bundle.getMessage('confirm-delete')}
      </Modal>}
      <SpaceBetween direction='vertical' size='m'>
        <Table
          columnDefinitions={ColumnDefinitions}
          empty={
            <>
              {bundle.getMessage('no-conditions-found')}
            </>
          }
          header={
            <Header
              counter={`(${deviceActionConditions.length})`}
              info={
                <>
                  {error
                  &&
                  <Alert
                    type='error'
                    dismissible
                    onDismiss={() => setError(undefined)}
                  >
                    {error}
                  </Alert>}
                </>
              }
              actions={
                <SpaceBetween direction='horizontal' size='s'>
                  <Button
                    disabled={
                      queryClient.isFetching({ queryKey: ['deviceActionConditions'] }) > 0
                      || queryClient.getQueryState(['deviceActionConditions'])?.status === 'loading'
                    }
                    iconName='refresh'
                    onClick={refresh}
                  />
                  <Button
                    disabled={selectedDeviceActionConditions.length === 0}
                    onClick={() => setShowConfirmDelete(true)}
                  >
                    {bundle.getMessage('delete')}
                  </Button>
                  <Button
                    disabled={
                      queryClient.isFetching({ queryKey: ['deviceActionConditions'] }) > 0
                      || queryClient.getQueryState(['deviceActionConditions'])?.status === 'loading'
                      || deviceActionConditions.findIndex(dac => dac.id === '') !== -1
                    }
                    onClick={() => addDeviceActionCondition()}
                    variant='primary'
                  >
                    {bundle.getMessage('new')}
                  </Button>
                </SpaceBetween>
              }
            >
              Conditions
            </Header>
        }
          items={items}
          loading={
            deviceActionConditionsQuery.isFetching
            || deviceActionConditionsQuery.isLoading
            || conditionOperandsQuery.isFetching
            || conditionOperandsQuery.isLoading}
          onSelectionChange={event => {
            setSelectedDeviceActionConditions(event.detail.selectedItems);
          }}
          selectionType='single'
          selectedItems={selectedDeviceActionConditions}
          submitEdit={submitEdit}
        />
      </SpaceBetween>
    </div>
  );
}
